import { Link, useParams } from "react-router-dom"
import brandbg from "../../image/brand-bg.png"
import { Loyalty } from "../../component/Loyalty"
import { FooterForm } from "../../component/FooterForm"
import { Pagination } from "../../component/Pagination"
import { useEffect, useState } from "react"
import { Blog } from "../../classes"
import { Loader } from "../../component/Loader"

export const BlogPage = () => {
    const [news, setNews] = useState(new Blog())
    const [load, setLoad] = useState(true)

    const LoadData = (page: number = 1) => {
        setLoad(true)
        news.LoadData(page).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert(error)
        })
    }

    useEffect(()=>{
        LoadData()
    },[])

    if(load){
        return(
            <Loader />
        )
    }
    return( 
        <>
            <section className="blog-list">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>Блог</span>
                    </div>
                    <h1 className="title">Блог</h1>

                    <div className="row brands-list">
                        { 
                            news.list.map((item)=>{
                                return(
                                    <div className="col-md-4 col-lg-3 col-big-2">
                                        <div className="brands-item">
                                            <div className="brands-image" style={{backgroundImage: `url('http://api.teststeps.ru${item.image}')`}}></div>
                                            <h2 className="brands-title">{item.title}</h2>
                                            <p className="brands-desc">{item.miniDesc}</p>
                                            <div className="blog-links">
                                                <Link to={`/blog/${item.slug}/`}>
                                                    Подробнее
                                                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7 17L17 7M17 7H8M17 7V16" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div> 
                                )
                            })
                        }
                    </div>

                    {
                        news.pageCount > 1 ? (
                            <Pagination
                                page={news.page}
                                pageCount={news.pageCount}
                                LoadProducts={LoadData}
                            />
                        ):""
                    }
                </div>
            </section>
            <Loyalty />
            <FooterForm />
        </>
    )
}