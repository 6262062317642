import { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Order } from "../../classes"
import { GlobalContext } from "../../context/GlobalContext"
import { Loader } from "../../component/Loader"

export const AccountOrderPage = () => {
    const auth = useContext(GlobalContext)
    if(auth.user.token == ""){ window.location.replace("/login/") }

    const { id } = useParams()
    const [order, setOrder] = useState(new Order())
    const [load, setLoad] = useState(true)

    useEffect(()=>{
        if(id){
            order.pk = Number(id)
            order.LoadData(auth.user.token).then(function(response){
                setLoad(false)
            }).catch(function(error){
                alert("error")
            })
        }
    },[])

    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <section className="account-list">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <Link to="/account/">Аккаунт</Link> / <span>Заказ</span>
                    </div>
                    <h1 className="title">Заказ №{id}</h1>

                    <div className="row">
                        <div className="col-md-9 order-2 order-md-1">
                            <div className="cart-products">
                                {
                                    order.product_order ? (
                                        order.product_order.map((item, index) => {
                                            return(
                                                <div className="cart-product__wrapper">
                                                    <div className="cart-product__image">
                                                        <div style={{
                                                            backgroundImage: `url('https://api.teststeps.ru${item.product?.image}')`
                                                        }}></div>
                                                    </div>
                                                    <div className="cart-product__content">
                                                        <div className="cart-product__header">
                                                            <span>Код товара: {item.product?.slug}</span>
                                                            Цена: {
                                                                item.price != item.salePrice ? (
                                                                    <b>{item.price} ₽</b>
                                                                ):""
                                                            } {item.salePrice} ₽ (за 1 шт)
                                                        </div>
                                                        <h3>
                                                            <Link to={`/product/${item.product?.slug}/`}>
                                                                {item.product?.title}
                                                            </Link>
                                                        </h3>
                                                    </div>
                                                    <div className="cart-product__size">
                                                        <div className="cart-product__header">
                                                            Размер
                                                        </div>
                                                        <p>
                                                            {item.size?.title}
                                                        </p>
                                                    </div>
                                                    <div className="cart-product__btns">
                                                        <div className="cart-product__header">
                                                            {item.salePrice * item.count} ₽ ({item.count} шт.)
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ):""
                                } 
                            </div>
                        </div>
                        <div className="col-md-3 order-1 order-md-2">
                            <div className="account-list__desc">
                                <h3><b>Дата заказа:</b> {order.updateDate}</h3>
                                <p>
                                    <b>Статус заказа: </b>{order.status}<br/>
                                    <b>Цена заказа: </b>{order.finPrice} руб.<br/>
                                    <b>Кол-во товаров: </b>{order.finCount}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}