import { Link } from "react-router-dom"
import InputMask from 'react-input-mask';
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Loader } from "../../component/Loader";
import { GlobalContext } from "../../context/GlobalContext";
import { AnswerBox } from "../../component/AnswerBox";


export const AccountSettingPage = () => {
    const auth = useContext(GlobalContext)
    if(auth.user.token == ""){ window.location.replace("/login/") }

    const [answerShow, setAnswerShow] = useState(false)
    const [answerText, setAnswerText] = useState("")
    
    const [form, setForm] = useState({
        fio: "",
        phone: "",
        address: "",
        city: "",
        region: "",
        index: "",
        password: "",
        cPassword: ""
    })
    const [load, setLoad] = useState(true)

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        setLoad(true)
        auth.user.SetSetting(form).then(function(response){
            if(response.error == false)
                window.location.reload()
            else{
                setLoad(false)
                setAnswerShow(true)
                setAnswerText(response.msg)
            }
        }).catch(function(error){
            alert("error")
        })
    }
    
    useEffect(()=>{
        auth.user.LoadSetting().then(function(response){
            setForm({
                fio: auth.user.fio,
                phone: auth.user.phone,
                address: auth.user.address,
                city: auth.user.city,
                region: auth.user.region,
                index: auth.user.index,
                password: "",
                cPassword: ""
            })
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])

    useEffect(()=>{
        if(answerShow == true && answerText != ""){
            setTimeout(()=>{
                setAnswerShow(false)
            }, 5000);
        }
    },[answerShow])
    

    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <section className="account-list">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <Link to="/account/">Аккаунт</Link> / <span>Настройки</span>
                    </div>
                    <h1 className="title">Настройки</h1>
                    
                    <div className="row">
                        <div className="col-md-3 col-lg-2">
                            <div className="account-menu">
                                <ul>
                                    <li>
                                        <Link to={`/account/`}>Заказы</Link>
                                    </li>
                                    <li>
                                        <Link className="active" to={`/account/setting/`}>Настройки</Link>
                                    </li>
                                    <li>
                                        <Link to={`/favorites/`}>Избранное</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-9 col-lg-10">
                            <form onSubmit={handleSubmit} className="account-setting">
                                <div className="account-setting__wrapper">
                                    <h4>Личные данные</h4>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-4">
                                            <label>
                                                <input type="text" name="fio" maxLength={255} placeholder="ФИО*" value={form.fio} onChange={changeHandler} />
                                            </label>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <label>
                                                <InputMask mask="+7 (999) 999-99-99" maxLength={255} required placeholder="Номер телефона" name="phone" value={form.phone} onChange={changeHandler} />
                                            </label>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <label> 
                                                <input type="text" placeholder="Адрес" maxLength={510} name="address" value={form.address} onChange={changeHandler} />
                                            </label>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <label> 
                                                <input type="text" placeholder="Город" maxLength={255} name="city" value={form.city} onChange={changeHandler} />
                                            </label>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <label>
                                                
                                                <input type="text" placeholder="Регион" maxLength={255} name="region" value={form.region} onChange={changeHandler} />
                                            </label>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <label>
                                                <input type="text" placeholder="Индекс" maxLength={255} name="index" value={form.index} onChange={changeHandler} />
                                            </label>
                                        </div>
                                    </div>
                                    <button>Обновить</button>
                                </div>

                                <div className="account-setting__wrapper">
                                    <h4>Пароль</h4>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-4">
                                            <label>
                                                <input type="password" minLength={8} placeholder="Пароль" name="password" value={form.password} onChange={changeHandler} />
                                            </label>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <label>
                                                <input type="password" minLength={8} required={form.password != "" ? true : false} placeholder="Повторите пароль" name="cPassword" value={form.cPassword} onChange={changeHandler} />
                                            </label>
                                        </div>
                                    </div>
                                    <button>Обновить</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            {
                answerShow ? (
                    <AnswerBox text={answerText} />
                ):""
            }
        </>
    )
}