import { Link } from "react-router-dom"

export const AfterOrderPage = () => {
    return(
        <>
            <section className="brand-content afterorder">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>Заказ</span>
                    </div>
                    <h1 className="title">Ваш заказ успешно оформлен</h1>
                    <p>Мы свяжемся с вами в ближайшее время.</p>
                    <Link className="catalog-filter__btn" to={`/`}>На главную</Link>
                </div>
            </section>
        </>
    )
}