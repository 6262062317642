import { Link } from "react-router-dom"
import Slider from "react-slick";
import { ProductInList } from "./ProductInList";
import { SampleNextArrow } from "./Arrows/SampleNextArrow";
import { SamplePrevArrow } from "./Arrows/SamplePrevArrow";

export const ProductSlider = (props: any) => {
    const setting = {
        slidesToShow: 6,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    arrows: false,
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 980,
                settings: {
                    arrows: false,
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 800,
                settings: {
                    arrows: false,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 650,
                settings: {
                    arrows: false,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            }
        ]
    };

    return(
        <section className="products-group">
            <div className="content">
                <h2 className="title">
                    {props.title}
                    <Link to={`${props.link}`}>Посмотреть все</Link>
                </h2>
                <Slider {...setting}>
                    {
                         props.list.length ? (
                            props.list.map((item: any, index: number) => {
                                return(
                                    <ProductInList key={index} data={item} />
                                )
                            })
                        ):""
                    }
                </Slider>
            </div>
        </section>
    )
}