import { Link } from "react-router-dom"

export const Page404 = () => {
    return(
        <>
            <section className="delivery">
                <div className="content" style={{paddingTop: 120, paddingBottom: 120}}>
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>Страница не найдена</span>
                    </div>
                    <h1 
                        className="title"
                        style={{
                            marginBottom: 20
                        }}
                    >
                        Страница не найдена
                    </h1>
                    <p
                        style={{
                            marginTop: 0,
                            fontSize: 16
                        }}
                    >
                        Вернуться на <Link style={{color: "#000"}} to={`/`}>главную страницу</Link>
                    </p>
                </div>
            </section>
        </>
    )
}