import { ChangeEvent, useContext, useState } from "react"
import { Link } from "react-router-dom"
import { GlobalContext } from "../context/GlobalContext"

export const LoginPage = () => {
    const auth = useContext(GlobalContext)
    if(auth.user.token != ""){ window.location.replace("/account/") }

    const [query, setQuery] = useState(false)
    const [answerShow, setAnswerShow] = useState(false)
    const [answerText, setAnswerText] = useState("")

    const [form, setForm] = useState({ 
        email: "",
        password: ""
    })
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(!query){
            auth.user.Login(form.email, form.password).then(function(response){
                if(response.error == false){
                    window.location.replace("/account/")
                }
                
                setForm({
                    email: "",
                    password: ""
                }) 
                setAnswerShow(true)
                setAnswerText(response.msg)
            }).catch(function(error){
                alert("error")
            })
        }
    }
    
    return(
        <>
            <section className="login">
                <div className="content">
                    <div className="login-wrapper">
                        <form onSubmit={handleSubmit} className="form-page">
                            <h1 className="title">Вход</h1>
                            <p>Зарегистрированным пользователям доступен личный кабинет с историей заказов</p>
                            <label>
                                Email
                                <input type="email" required value={form.email} name="email" onChange={changeHandler} />
                            </label>
                            <label>
                                Пароль <Link to="/password/">Забыли пароль?</Link>
                                <input type="password" required value={form.password} name="password" onChange={changeHandler} />
                            </label>
                            <div className="btn-link">
                                <button>Войти</button>
                                <Link to="/registration/">Регистрация</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}