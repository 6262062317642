import { Link } from "react-router-dom"

export const ObmenPage = () => {
    return(
        <>
            <section className="delivery">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>Обмен и возврат</span>
                    </div>
                    <h1 className="title">Обмен и возврат</h1>

                    <h3>Обмен</h3>
                    <p>Вы можете обменять товар, купленный в нашем магазине, при соблюдении следующих условий:</p>
                    <p>
                        • с момента получения товара и на момент его обмена прошло не более 14 календарных дней;<br/>
                        • товар не был в употреблении (стиран, ношен);<br/>
                        • сохранены его товарный вид, потребительские свойства;<br/>
                        • пломбы, фабричные ярлыки, в том числе КИЗ (контрольно-идентификационный знак) на товаре или его упаковке (в зависимости от того, что применимо) должны быть целыми, не мятыми и не повреждёнными.
                    </p>
                    <p>Если условия не соблюдены, мы не сможем обменять ваш товар.</p>
                    <p>Обратите внимание, что при обмене вы самостоятельно несете все связанные с этим расходы, в том числе расходы на доставку.</p>
                    <h4>Как запросить обмен?</h4>
                    <p>Для того, чтобы запросить обмен, напишите нам на адрес электронной почты step.hsnks@yandex.ru письмо с темой "Обмен". В письме необходимо обязательно указать: </p>
                    <p>
                        • номер вашего заказа, который можно найти в личном кабинете, или, если вы оформляли заказ в качестве гостя, в электронном письме с подтверждением заказа;<br/>
                        • контактный номер телефона.
                    </p>
                    <p>После получения письма с вами по телефону свяжется наш сотрудник.</p>

                    <h4>Отправка обмена</h4>
                    <p>Отправить товар для обмена необходимо по адресу: Москва, Цветной Бульвар 22. Время работы: 12:00 - 22:00 без выходных.</p>
                    <p>Внимание! Пожалуйста, не отправляйте товар без предварительного уведомления, заранее напишите нам на адрес электронной почты step.hsnks@yandex.ru.</p>
                    <p>Оригинальная коробка товара, в которой вы получили вещь, не подходит для транспортировки, так как считается частью комплекта. Если она повредится при перевозке, мы не сможем принять обмен. Мы настоятельно рекомендуем проследить за тем, чтобы брендированная коробка была помещена вами или сотрудниками СДЕК в фирменную транспортную коробку СДЕК для сохранения целостности и товарного вида всего комплекта.</p>
                    <p>Пыльники, чехлы и другие аксессуары из комплекта также нужно вернуть вместе с вещью.</p>
                    <p>Обратите внимание, что затраты на доставку товара до нашего офиса возмещению не подлежат – их необходимо оплатить самостоятельно. </p>

                    <h3>Возврат</h3>
                    <p>Вы можете вернуть товар, купленный в нашем магазине, при соблюдении следующих условий:</p>
                    <p>
                        •	с момента получения товара и на момент его возврата прошло не более 14 календарных дней;<br/>
                        •	товар не был в употреблении (стиран, ношен);<br/>
                        •	сохранены его товарный вид, потребительские свойства;<br/>
                        •	пломбы, фабричные ярлыки, в том числе КИЗ (контрольно-идентификационный знак) на товаре или его упаковке (в зависимости от того, что применимо) должны быть целыми, не мятыми и не повреждёнными.
                    </p>
                    <p>Если условия возврата не соблюдены, и возвращенные товары не могут быть приняты, клиент получит соответствующее уведомление на электронную почту. В письме будет указана причина отказа.</p>
                    <p>В этом случае по просьбе клиента возвращенные товары могут быть отосланы обратно, связанные с этим расходы оплачиваются клиентом. Если клиент отказывается от отправки товаров, мы сохраняем за собой право оставить товары у себя без возврата их стоимости. </p>
                    <p>При возникновении спора о факте употребления товара и сохранности его товарного вида производится независимая экспертиза за счёт клиента.</p>
                    
                    <h4>Как запросить возврат?</h4>
                    <p>Для того, чтобы запросить возврат, напишите нам на адрес электронной почты step.hsnks@yandex.ru письмо с темой "Возврат". В письме необходимо обязательно указать: </p>
                    <p>
                        •	номер вашего заказа, который можно найти в личном кабинете, или, если вы оформляли заказ в качестве гостя, в электронном письме с подтверждением заказа;<br/>
                        •	причину возврата;<br/>
                        •	контактный номер телефона.
                    </p>
                    <p>После получения письма с вами по телефону свяжется наш сотрудник.</p>

                    <h4>Отправка возврата</h4>
                    <p>Возврат товара из интернет-магазина осуществляется по адресу: Москва, Цветной Бульвар 22. Время работы: 12:00 - 22:00 без выходных.</p>
                    <p>Внимание! Пожалуйста, не возвращайте товар без предварительного уведомления, заранее напишите нам на адрес электронной почты step.hsnks@yandex.ru.</p>
                    <p>Оригинальная коробка товара, в которой вы получили вещь, не подходит для транспортировки, так как считается частью комплекта. Если она повредится при перевозке, мы не сможем принять возврат. Мы настоятельно рекомендуем проследить за тем, чтобы брендированная коробка была помещена вами или сотрудниками СДЕК в фирменную транспортную коробку СДЕК для сохранения целостности и товарного вида всего комплекта.</p>
                    <p>Пыльники, чехлы и другие аксессуары из комплекта также нужно вернуть вместе с вещью.</p>
                    <p>Обратите внимание, что затраты на доставку товара до нашего офиса возмещению не подлежат – их необходимо оплатить самостоятельно. Исключениями являются: бракованный или дефектный товар, а также случаи ошибочно отправленного товара.</p>
        
                    <h4>Как и когда будут возвращены деньги?</h4>
                    <p>После получения мы проверим предмет возврата на соблюдение условий возврата в течение 2 рабочих дней, и, если товар соответствует требованиям, деньги будут возвращены в течение 10 рабочих дней тем же способом, которым вы оплатили заказ.</p>
                    <p>Возврат денежных средств осуществляется без учета стоимости доставки. Все расходы, связанные с обратной транспортировкой возврата, несет клиент.</p>
                    <p>Все случаи возврата товаров в нашем магазине регулируются действующими на территории России законами:</p>
                    <p>Постановлением Правительства РФ от 31.12.2020 N 2463 "Об утверждении Правил продажи товаров по договору розничной купли-продажи, перечня товаров длительного пользования, на которые не распространяется требование потребителя о безвозмездном предоставлении ему товара, обладающего этими же основными потребительскими свойствами, на период ремонта или замены такого товара, и перечня непродовольственных товаров надлежащего качества, не подлежащих обмену, а также о внесении изменений в некоторые акты Правительства Российской Федерации";</p>
                    <p>Федеральным Законом от 07 февраля 1992 года N 2300-1 "О защите прав потребителей".</p>

                </div>
            </section>
        </>
    )
}