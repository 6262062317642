import { Link } from "react-router-dom"
import { Loyalty } from "../component/Loyalty"
import { FooterForm } from "../component/FooterForm"

export const AboutPage = () => {
    return( 
        <>
            <section className="delivery">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>О нас</span>
                    </div>
                    <h1 className="title">О нас</h1>
                    <p>STEPHSNKS — магазин, который занимается не только продажей и поиском оригинальных лимитированных кроссовок. Мы расширили всеобщее представление о ресейл-платформах и теперь помогаем нашим клиентам в поиске труднодоступных люксовых предметов гардероба и аксессуаров.</p>
                    <p>Наша команда профессионалов помогает найти и подобрать любые вещи из новейших коллекций люксовых брендов в кратчайшие сроки с доставкой прямо до вашей двери.</p>
                </div>
            </section>

            <Loyalty />
            <FooterForm />
        </>
    )
}