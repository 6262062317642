import { Link } from "react-router-dom"
import { FooterForm } from "../component/FooterForm"
import { Loyalty } from "../component/Loyalty"
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { Loader } from "../component/Loader"
import { CartProduct } from "../component/CartProduct"
import InputMask from 'react-input-mask';

export const CartPage = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true) 
    const [cart, setCart] = useState(auth.user.cart)

    const [loadUpdate, setLoadUpdate] = useState(false) 

    const [form, setForm] = useState({
        fio: "",
        phone: "",
        email: "",
        address: "",
        city: "",
        region: "",
        index: "",
        comment: "",
        deliveryType: "shop",
        contactType: "phone",
        payType: "cart"
    })
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    useEffect(()=>{
        cart.GetCart(auth.user.token).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
        
        if(auth.user.token != ""){
            auth.user.LoadSetting().then(function(response){
                setForm({
                    fio: auth.user.fio,
                    phone: auth.user.phone,
                    email: "",
                    address: auth.user.address,
                    city: auth.user.city,
                    region: auth.user.region,
                    index: auth.user.index,
                    comment: "",
                    deliveryType: "shop",
                    contactType: "phone",
                    payType: "cart"
                }) 
            }).catch(function(error){
                alert("error")
            })
        }
    },[])


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoad(true)
        cart.McOrder(auth.user.token, form).then(function(response){
            if(response.error == false)
                window.location.replace(response.link);
            else
                window.location.reload();
        }).catch(function(error){
            alert("error")
        })
    }

    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <section className="cart">
                <form className="content" onSubmit={handleSubmit}>
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>Корзина</span>
                    </div>
                    <div className="cart-wrapper">
                        <div className="cart-space order-2 order-lg-1 placeholder-wrapper">
                            {
                                loadUpdate ? (
                                    <div className="placeholder-cart">
                                        <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 2.99988V5.99988M12 20.9999V17.9999M4.20577 16.4999L6.80385 14.9999M21 11.9999H18M16.5 19.7941L15 17.196M3 11.9999H6M7.5 4.20565L9 6.80373M7.5 19.7941L9 17.196M19.7942 16.4999L17.1962 14.9999M4.20577 7.49988L6.80385 8.99988" stroke="#666" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                ):""
                            }
                            {
                                cart.cartProducts.length ? (
                                    <>
                                        <div className="row cart-btns">
                                            <div className="col-md-6">
                                                <div 
                                                    className="cbtn"
                                                    onClick={()=>{
                                                        setLoad(true)
                                                        cart.ClearCart(auth.user.token).then(function(response){
                                                            setLoad(false)
                                                        }).catch(function(error){
                                                            alert("error")
                                                        }) 
                                                    }}
                                                >
                                                    Очистить корзину
                                                </div>
                                            </div>
                                            <div className="col-md-6 right">
                                                <Link to={`/catalog/`}>
                                                    <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M26 10.1C26.6075 10.1 27.1 9.60751 27.1 9C27.1 8.39249 26.6075 7.9 26 7.9L26 10.1ZM1.22218 8.22218C0.792606 8.65176 0.792606 9.34824 1.22218 9.77782L8.22254 16.7782C8.65212 17.2078 9.3486 17.2078 9.77818 16.7782C10.2078 16.3486 10.2078 15.6521 9.77818 15.2225L3.55564 9L9.77817 2.77746C10.2078 2.34788 10.2078 1.6514 9.77817 1.22183C9.3486 0.79225 8.65212 0.79225 8.22254 1.22183L1.22218 8.22218ZM26 7.9L2 7.9L2 10.1L26 10.1L26 7.9Z" fill="black" />
                                                    </svg>
                                                    Вернуться<br/>
                                                    к покупкам
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="cart-products">
                                            {
                                                cart.cartProducts ? (
                                                    cart.cartProducts.map((item, index) => {
                                                        return(
                                                            <CartProduct 
                                                                item={item} 
                                                                setLoad={setLoad}
                                                                cart={cart}
                                                                setLoadUpdate={setLoadUpdate}
                                                            />
                                                        )
                                                    })
                                                ):""
                                            } 
                                        </div>

                                        <div className="cart-form">
                                            <div className="cart-form__block">
                                                <h3>Ваши данные</h3>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="text" name="fio" required maxLength={510} placeholder="Укажите ваше ФИО" value={form.fio} onChange={changeHandler} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputMask mask="+7 (999) 999-99-99" required placeholder="Укажите ваш номер телефона" name="phone" value={form.phone} onChange={changeHandler} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input type="text" maxLength={255} name="email" required placeholder="Укажите вашу почту" value={form.email} onChange={changeHandler} />
                                                    </div>
                                                    
                                                    {
                                                        form.deliveryType != "shop" ? (
                                                            <>
                                                                <div className="col-md-6">
                                                                    <input type="text" maxLength={510} name="address" required placeholder="Адрес" value={form.address} onChange={changeHandler} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input type="text" maxLength={255} name="city" required placeholder="Город" value={form.city} onChange={changeHandler} />
                                                                </div>
                                                                <div className="col-md-4" style={{display: "none"}}>
                                                                    <input type="text" maxLength={255} name="region" placeholder="Регион" value={form.region} onChange={changeHandler} />
                                                                </div>
                                                                <div className="col-md-4" style={{display: "none"}}>
                                                                    <input type="text" maxLength={255} name="index" placeholder="Почтовый индек" value={form.index} onChange={changeHandler} />
                                                                </div>
                                                            </>
                                                        ):""
                                                    }

                                                    <div className="col-12">
                                                        <input type="text" name="comment" maxLength={510} placeholder="Комментарий" value={form.comment} onChange={changeHandler} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="cart-form__block">
                                                <h3>Способ оплаты</h3>
                                                <div 
                                                    className={`cart-form__radio ${form.payType == "cart" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, payType : "cart" })
                                                    }}
                                                >
                                                    Банковская карта
                                                </div>    
                                                <div 
                                                    className={`cart-form__radio ${form.payType == "transaction" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, payType : "transaction" })
                                                    }}
                                                >
                                                    Банковский перевод по счету
                                                </div>    
                                                <div 
                                                    className={`cart-form__radio ${form.payType == "cash" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, payType : "cash" })
                                                    }}
                                                >
                                                    Наличными при получении
                                                </div>    
                                            </div>





                                            <div className="cart-form__block">
                                                <h3>Способ доставки</h3>
                                                <div 
                                                    className={`cart-form__radio ${form.deliveryType == "shop" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, deliveryType : "shop" })
                                                    }}
                                                >
                                                    Самовывоз
                                                    {
                                                        form.deliveryType == "shop" ? (
                                                            <div className="cart-form__desc">
                                                                Сложно сказать, почему активно развивающиеся страны третьего мира разоблачены. Как уже неоднократно упомянуто, представители современных социальных резервов формируют глобальную экономическую сеть и при этом — преданы социально-демократической анафеме. Повседневная практика показывает, что внедрение современных методик требует определения и уточнения инновационных методов управления процессами.
                                                            </div>
                                                        ):""
                                                    }
                                                </div>  
                                                <div 
                                                    className={`cart-form__radio ${form.deliveryType == "courier" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, deliveryType : "courier" })
                                                    }}
                                                >
                                                    Курьером
                                                    {
                                                        form.deliveryType == "courier" ? (
                                                            <div className="cart-form__desc">
                                                                Сложно сказать, почему активно развивающиеся страны третьего мира разоблачены. Как уже неоднократно упомянуто, представители современных социальных резервов формируют глобальную экономическую сеть и при этом — преданы социально-демократической анафеме. Повседневная практика показывает, что внедрение современных методик требует определения и уточнения инновационных методов управления процессами.
                                                            </div>
                                                        ):""
                                                    }
                                                </div> 
                                                <div 
                                                    className={`cart-form__radio ${form.deliveryType == "cdek" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, deliveryType : "cdek" })
                                                    }}
                                                >
                                                    СДЭК
                                                    {
                                                        form.deliveryType == "cdek" ? (
                                                            <div className="cart-form__desc">
                                                                Сложно сказать, почему активно развивающиеся страны третьего мира разоблачены. Как уже неоднократно упомянуто, представители современных социальных резервов формируют глобальную экономическую сеть и при этом — преданы социально-демократической анафеме. Повседневная практика показывает, что внедрение современных методик требует определения и уточнения инновационных методов управления процессами.
                                                            </div>
                                                        ):""
                                                    }
                                                </div> 
                                                <div 
                                                    className={`cart-form__radio ${form.deliveryType == "boxberry" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, deliveryType : "boxberry" })
                                                    }}
                                                >
                                                    Boxberry
                                                    {
                                                        form.deliveryType == "boxberry" ? (
                                                            <div className="cart-form__desc">
                                                                Сложно сказать, почему активно развивающиеся страны третьего мира разоблачены. Как уже неоднократно упомянуто, представители современных социальных резервов формируют глобальную экономическую сеть и при этом — преданы социально-демократической анафеме. Повседневная практика показывает, что внедрение современных методик требует определения и уточнения инновационных методов управления процессами.
                                                            </div>
                                                        ):""
                                                    }
                                                </div> 
                                                <div 
                                                    className={`cart-form__radio ${form.deliveryType == "mail" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, deliveryType : "mail" })
                                                    }}
                                                >
                                                    Почта России
                                                    {
                                                        form.deliveryType == "mail" ? (
                                                            <div className="cart-form__desc">
                                                                Сложно сказать, почему активно развивающиеся страны третьего мира разоблачены. Как уже неоднократно упомянуто, представители современных социальных резервов формируют глобальную экономическую сеть и при этом — преданы социально-демократической анафеме. Повседневная практика показывает, что внедрение современных методик требует определения и уточнения инновационных методов управления процессами.
                                                            </div>
                                                        ):""
                                                    }
                                                </div> 
                                            </div>

                                            <div className="cart-form__block">
                                                <h3>Удобный вид связи</h3> 
                                                <div 
                                                    className={`cart-form__radio ${form.contactType == "phone" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, contactType : "phone" })
                                                    }}
                                                >
                                                    Номер телефона
                                                </div>  
                                                <div 
                                                    className={`cart-form__radio ${form.contactType == "whatsApp" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, contactType : "whatsApp" })
                                                    }}
                                                >
                                                    WhatsApp
                                                </div> 
                                                <div 
                                                    className={`cart-form__radio ${form.contactType == "telegram" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, contactType : "telegram" })
                                                    }}
                                                >
                                                    Telegram
                                                </div> 
                                                <div 
                                                    className={`cart-form__radio ${form.contactType == "email" ? "active":""}`}
                                                    onClick={()=>{
                                                        setForm({ ...form, contactType : "email" })
                                                    }}
                                                >
                                                    Email
                                                </div>  
                                            </div>


                                            {
                                                !cart.orderAvailable ? (
                                                    <p 
                                                        className="order-available__text"
                                                    >
                                                        В корзине есть недоступные товары
                                                    </p>
                                                ):(
                                                    <button className="cart-price__mc-order">Заказать</button>
                                                )
                                            }
                                            
                                        </div>
                                    </>
                                ):(
                                    <h3
                                        style={{
                                            fontSize: 32,
                                        }}
                                    >
                                        Корзина пуста
                                    </h3>
                                )
                            }
                        </div>
                        <div className="cart-price order-1 order-lg-2">
                            <div className="cart-price__wrapper">
                                <h2>В корзине </h2>
                                <div className="cart-price__count">
                                    <b>{cart.productCount}</b> товар(а)<br/>итого
                                </div>
                                <div className="cart-price__final">
                                    <span>Цена</span>
                                    {
                                        cart.productFullPrice != cart.productPrice ? (
                                            <div className="cart-price__full">{cart.productFullPrice} ₽</div>
                                        ):""
                                    }
                                    <div>{cart.productPrice} ₽</div>
                                </div>

                                {
                                    form.deliveryType != "shop" ? (
                                        <div className="cart-price__delivery">
                                            <span>Доставка</span>
                                            <div>+ {
                                                form.deliveryType == "courier" ? "100" :
                                                form.deliveryType == "cdek" ? "200" :
                                                form.deliveryType == "boxberry" ? "300" : "400"
                                            }₽</div>
                                        </div>
                                    ):""
                                }
                                <div style={{display: "none"}}>
                                    <input placeholder="Промокод" />
                                    <button>Применить</button>
                                </div>

                                {
                                    !cart.orderAvailable ? (
                                        <p 
                                            className="order-available__text"
                                        >
                                            В корзине есть недоступные товары
                                        </p>
                                    ):(
                                        <button className="cart-price__mc-order">Заказать</button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            
            <Loyalty />
            <FooterForm />
        </>
    )
}