import { Link } from 'react-router-dom'
import loyalty from '../image/loyalty.png'

export const Loyalty = () => {
    return(
        <section className="loyalty">
            <div className="content">
                <div className="row">
                    <div className="col-md-7">
                        <h2>Программа лояльности</h2>
                        <p>Для участия в программе лояльности пользователю обычно необходимо зарегистрироваться на сайте интернет-магазина и получить персональную карту или привязать свой аккаунт к программе. Это позволяет магазину отслеживать покупки клиента, накапливать бонусы и предлагать персональные акции и специальные предложения.</p>
                        <p>Программа лояльности для интернет-магазина способствует увеличению лояльности клиентов, повышению их уровня удовлетворенности, а также стимулирует их к повторным покупкам.</p>
                        <Link 
                            to={`/registration/`}
                        >
                            Зарегистрироваться
                        </Link>
                    </div>
                    <div className="col-md-5 center">
                        <img src={loyalty} />
                    </div>
                </div>
            </div>
        </section>
    )
}