import { Link } from "react-router-dom"
import { Loyalty } from "../../component/Loyalty"
import { FooterForm } from "../../component/FooterForm"
import brandbg from "../../image/brand-bg.png"
import { useEffect, useState } from "react"
import { Brands } from "../../classes"
import { Loader } from "../../component/Loader"
import { Pagination } from "../../component/Pagination"

export const BrandPage = () => {
    const [brands, setBrands] = useState(new Brands())
    const [load, setLoad] = useState(true)
    const [sort, setSort] = useState("-pk")

    const LoadData = (page: number = 1, sort: string = "-pk") => {
        setLoad(true)
        brands.LoadData(page, sort).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert(error)
        })
    }

    useEffect(()=>{
        LoadData()
    },[])

    if(load){
        return(
            <Loader />
        )
    }



    return(
        <>
            <section className="brands">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>Бренды</span>
                    </div>
                    <h1 className="title">Бренды</h1>
                    <p className="desc">
                        В магазинах предоставлен широкий выбор кроссовок и кед для повседневной жизни от мировых брендов со спортивными наследием 
                    </p>

                    <div className="brand-filter">
                        <form>
                            <label>
                                Тип сортировки

                                <select onChange={(e)=>{
                                        setSort(e.target.value)
                                        LoadData(1, e.target.value)
                                }}>
                                    <option value={'-pk'} selected={sort == "-pk"}>По новизне</option>
                                    <option value={'-rating'} selected={sort == "-rating"}>По популярности</option> 
                                    <option value={'-priceMid'} selected={sort == "-priceMid"}>По среднему чеку</option>
                                </select>
                            </label>    
                        </form>
                    </div>

                    <div className="brands-list">
                        {
                            brands.list.length ? (
                                brands.list.map((item, index)=>{
                                    return(
                                        <div className="row brands-list__wrapper">
                                            <div className={`col-md-4 col-lg-3 order-1 ${index % 2 == 0 ? "order-md-2" : "order-md-1"}`}>
                                                <div className="brands-image" style={{backgroundImage: `url('http://api.teststeps.ru${item.logo}')`}}></div>
                                            </div>
                                            <div className={`col-md-8 col-lg-9 brands-item__content order-1 ${index % 2 == 0 ? "order-md-1" : "order-md-2"}`}>
                                                <div className="grid">
                                                    <div>
                                                        <h3>{item.title}</h3>
                                                        <div className="brands-desc" dangerouslySetInnerHTML={{ __html: item.miniDesc} } />
                                                        <div className="brands-links">
                                                            <Link to={`/brands/${item.slug}/`}>История бренда</Link>
                                                            <Link to={`/catalog/?brand=${item.pk}`}>Показать в каталоге</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ):""
                        }
                    </div>

                    {
                        brands.pageCount > 1 ? (
                            <Pagination
                                page={brands.page}
                                pageCount={brands.pageCount}
                                LoadProducts={LoadData}
                            />
                        ):""
                    }
                </div>
            </section>

            <Loyalty />

            <FooterForm />
        </>
    )
}