import { Link } from "react-router-dom"

export const OfertaPage = () => {
    return(
        <>
            <section className="delivery">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>ПУБЛИЧНЫЙ ДОГОВОР – ОФЕРТА</span>
                    </div>
                    <h1 className="title">ПУБЛИЧНЫЙ ДОГОВОР – ОФЕРТА</h1>
                    <p>интернет- магазина «STEPHSNKS»</p>
                    <h3>1.	ИСПОЛЬЗУЕМЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>
                    <p>1.1.	В настоящей оферте, если контекст не требует иного, нижеприведенные термины имеют следующие значения и являются ее составной неотъемлемой частью:</p>
                    <p>1.2.	Продавец – ООО "СТЕФСНИКС", ИНН 9704061711, ОГРН 1217700190498.</p>
                    <p>1.3.	Покупатель (Заказчик) – полностью дееспособное физическое лицо, размещающее Заказы на сайте http://stephsnks.ru/, либо указанное в качестве получателя Товара, либо использующее Товары, приобретенные на сайте http://stephsnks.ru/, исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности.</p>
                    <p>1.4.	Перевозчик – курьерская служба или транспортная компания, осуществляющая доставку заказа по адресу, указанному Покупателем.</p>
                    <p>1.5.	Оферта – публичное предложение Продавца, адресованное любому физическому лицу (гражданину), заключить с ним договор купли-продажи (далее - "Договор") на существующих условиях, содержащихся в Договоре, включая все его приложения.</p>
                    <p>1.6.	Акцепт оферты – полное и безоговорочное принятие условий оферты путем осуществления действий Покупателем (Заказчиком), выражающих намерение воспользоваться услугами Исполнителя для получения Товаров, размещенных на сайте http://stephsnks.ru/.</p>
                    <p>1.7.	Заказ – должным образом, оформленный запрос Покупателя на приобретение и доставку по указанному адресу перечня Товаров, представленного на Сайте.</p>
                    <p>1.8.	Товар - объект материального мира, не изъятый из гражданского оборота и представленный к продаже на Сайте.</p>
                    <p>1.9.	Сайт - открытый для свободного ознакомления, публично доступный для физических и юридических лиц, расположенный в сети интернет по адресу http://stephsnks.ru/.</p>
                    <p>1.10.	Интернет магазин - интернет-сайт, принадлежащий Продавцу, имеющий адрес в сети Интернет http://stephsnks.ru/. На нем представлены товары, предлагаемые Продавцом своим Покупателям (Заказчикам) для оформления Заказов, а также условия оплаты, доставки возврата и обмена товаров.</p>

                    <h3>2.	ОБЩИЕ ПОЛОЖЕНИЯ</h3>
                    <p>2.1.	Настоящий публичный Договор - Оферта является официальным предложением Продавца в адрес любого физического лица, обладающего дееспособностью и необходимым полномочием заключить договор на условиях, определенных в настоящей оферте, и содержит все существенные условия договора.</p>
                    <p>2.2.	Данная Оферта распространяется на все виды товаров и услуг, представленных на Сайте, пока такие предложения с описанием присутствуют в каталоге Интернет-магазина.</p>
                    <p>2.3.	Покупатель безоговорочно принимает условия Соглашения посредством оформления Заказа на Сайте или с помощью оператора по телефону, указанному на Сайте, после чего Продавец и Покупатель вступают в договорные отношения.</p>
                    <p>2.4.	Сделка считается совершенной, в момент окончательного подтверждения заказа Покупателем, подтверждения цены и наличия товара Продавцом, при этом Продавец обязуется передать в собственность Покупателю, а Покупатель обязуется оплатить и принять заказанные товары.</p>
            
                    <h3>3.	ПРЕДМЕТ ДОГОВОРА</h3>
                    <p>3.1.	Предметом настоящего Соглашения является предоставление возможности Пользователю приобретать для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности, товары, представленные в каталоге Интернет- магазина по адресу: http://stephsnks.ru/.</p>
                    <p>3.2.	Доставка товаров, заказанных и оплаченных Покупателем, осуществляется Продавцом или Перевозчиком. Покупатель имеет право забрать товар со склада Продавца самостоятельно (самовывоз). Покупателю при оформлении заказа предоставляется право выбора способа доставки.</p>
                    <p>3.3.	К отношениям между Покупателем и Продавцом применяются положения ГК РФ о розничной купле- продаже (§ 2 глава 30), Закон РФ «О защите прав потребителей» от 07.02.1992 №2300- 1, а также иные нормативные правовые акты, принятые в соответствии с ними.</p>
 
                    <h3>4.	ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА КУПЛИ-ПРОДАЖИ</h3>

                    <p>4.1.	Покупатель может оформить заказ самостоятельно на сайте интернет-магазина, либо с помощью оператора по телефону, указанному на Сайте, на условиях Договора купли-продажи (публичной оферты интернет-магазина).</p>
                    <p>4.2.	При оформлении заказа в интернет-магазине, Покупатель обязан предоставить следующую информацию:<br/>
                        •	Ф.И.О.;<br/>
                        •	контактный телефон;<br/>
                        •	электронную почту Покупателя товара;<br/>
                        •	адрес доставки товара или выбрать точку самовывоза.
                    </p>
                    <p>4.3.	Волеизъявление Покупателя осуществляется посредством внесения последним соответствующих данных в форму заказа в интернет-магазине либо подачей заявки через менеджера интернет-магазина или по e-mail.</p>
                    <p>4.4.	Интернет-магазин не редактирует информацию о поступающих заявках и Покупателе. Дополнение информации о Покупателе, заказанных товарах возможно по устному согласованию Сторон договора.</p>

                    <h3>5.	ИНФОРМАЦИЯ О ТОВАРЕ</h3>

                    <p>5.1.	Товар представлен на сайте через фото-образцы, являющиеся собственностью интернет- магазина.</p>
                    <p>5.2.	Каждый фото-образец сопровождается текстовой информацией: наименованием, ценой и описанием товара.</p>
                    <p>5.3.	Все информационные материалы, представленные в интернет - магазине, носят справочный характер. В случае возникновения у Покупателя вопросов, касающихся свойств и характеристик товара, Покупатель может обратиться к Продавцу по телефонам, указанным на сайте, а также по адресу электронной почты Продавца.</p>
                    <p>5.4.	По просьбе Покупателя менеджер интернет-магазина обязан предоставить (по телефону или посредством электронной почты) прочую информацию, необходимую и достаточную, с точки зрения Покупателя, для принятия им решения о покупке товара.</p>
                    <p>5.5.	Покупатель уведомлен о том, что, приобретая товар со скидкой, установленной в связи с его недостатками (дефектами), он лишается права ссылаться на них в дальнейшем.</p>

                    <h3>6.	ЦЕНА ТОВАРА</h3>
                    <p>6.1.	Цена товара в интернет-магазине указана в рублях РФ за единицу товара. Цена товара не включает стоимость доставки товара до Покупателя и иные услуги Продавца.</p>
                    <p>6.2.	Указанная на сайте цена товара может быть изменена интернет-магазином в одностороннем порядке, при этом цена на оплаченный Покупателем товар изменению не подлежит.</p>
                    <p>6.3.	Продавец вправе предоставлять скидки на товары, проводить различные акции и устанавливать программу бонусов. Виды скидок, бонусов, порядок и условия начисления определяются Продавцом самостоятельно и указываются в разделе «Акции» интернет-магазина.</p>
                    <p>6.4.	Полная стоимость заказа состоит из каталожной стоимости товара, а также стоимости его доставки.</p>
                    
                    <h3>7.	ПОРЯДОК ПРИОБРЕТЕНИЯ И ОПЛАТЫ ТОВАРА</h3>
                    <p>7.1.	Покупатель вправе оформить заказ на любой товар, представленный в интернет- магазине. Каждый товар может быть заказан в любом количестве. Исключения из указанного правила указаны в описании каждого товара в случае проведения акций, снятия товара с продажи и т.п.</p>
                    <p>7.2.	Заказ может быть оформлен Покупателем по телефонам, указанным на Сайте или оформлен самостоятельно на Сайте.</p>
                    <p>7.3.	После оформления заказа на e-mail Покупателя отправляется подтверждение принятия заказа с указанием наименования, цены выбранного товара и общей суммы заказа, являющийся неотъемлемой частью настоящего договора.</p>
                    <p>7.4.	При отсутствии товара на складе менеджер интернет-магазина обязан поставить в известность об этом Покупателя (по телефону или посредством электронной почты).</p>
                    <p>7.5.	Покупатель вправе сделать предварительный заказ на временно отсутствующий на складе товар, либо заменить его другим товаром или аннулировать заказ.</p>
                    <p>7.6.	Заказ, доставляемый Покупателю с помощью курьерской службы/транспортной компании, принимается к исполнению только после полной его оплаты.</p>
                    <p>7.7.	При осуществлении оплаты товара Покупатель вправе использовать бонусы накопительной бонусной программы. По техническим причинам возможность использования бонусов при оплате заказа может быть временно недоступна без дополнительного уведомления Пользователей.</p>
                    <p>7.8.	Информация о состоянии бонусного счета, количестве бонусов, доступных к списанию, доступны на сайте http://stephsnks.ru/ в личном кабинете Покупателя.</p>
                    <p>7.9.	Оплата осуществляется при оформлении заказа на Сайте с помощью банковских карт (VISA, MasterCard, МИР) через АО "РОССЕЛЬХОЗБАНК" или в точках самовывоза Продавца, расположенных в г. Москва, наличными денежными средствами, банковской картой.</p>
                    <p>7.10.	При безналичной форме оплаты обязанность Покупателя по уплате цены товара считается исполненной с момента зачисления соответствующих денежных средств на расчетный счет, указанный Продавцом.</p>
                    <p>7.11.	При наличной форме оплаты Покупатель обязан уплатить Продавцу цену товара в момент его приема-передачи, а Продавец обязан предоставить Покупателю кассовый или товарный чек, или иной документ, подтверждающий оплату товара.</p>

                    <h3>8.	ДОСТАВКА ТОВАРОВ</h3>
                    <p>8.1.	Способы, порядок и сроки доставки товара указаны на Сайте в разделе «Доставка и оплата». Срок доставки зависит от выбранного способа доставки товара. Порядок и условия доставки заказанного товара оговариваются Покупателем с менеджером Интернет-магазина. Доставленный товар передается Покупателю по указанному им адресу, а при отсутствии покупателя – любому лицу, предъявившему информацию о номере заказа, либо иное (в том числе электронное) подтверждение оформления заказа.</p>

                    <h3>САМОВЫВОЗ ТОВАРА</h3>
                    <p>8.2.	Продавец, получив уведомление о размещенном заказе, подтверждает его получение по телефону или по e-mail Покупателя и согласовывает с ним дату самовывоза товара.</p>
                    <p>8.3.	Покупатель оплачивает (при наличной форме оплаты) и получает заказ по месту нахождения пунктов самовывоза Продавца. Адреса, контакты и режим работы пунктов указаны на Сайте Продавца в разделе «Контакты».</p>
                    <p>8.4.	Самовывоз заказа осуществляется Покупателем лично или его уполномоченным Представителем. Данные представителя сообщаются Продавцу Покупателем до момента фактической выдачи заказа.</p>
                    <p>8.5.	При получении товара Представителем, последний обязуется предоставить достаточные данные, позволяющие идентифицировать заказ, включая, но не ограничиваясь Ф.И.О. Покупателя, номер и (или) сумму заказа, позиции заказа, подлежащие выдаче.</p>
                    <p>8.6.	Право собственности и риск случайной гибели, утраты или повреждения товара переходит к Покупателю с момента передачи товара Покупателю или его Представителю.</p>

                    <h3>КУРЬЕРСКАЯ ДОСТАВКА</h3>
                    <p>8.7.	Право собственности и риск случайной гибели, утраты или повреждения товара переходит с Продавца на Покупателя с момента передачи товара Перевозчику при подписании Сторонами товарной накладной и/или транспортной накладной и/или товарно-транспортной накладной или иного документа, свидетельствующего о передаче товара.</p>
                    <p>8.8.	Стоимость доставки товара в рамках каждого заказа рассчитывается исходя из веса всех заказанных товаров, адреса доставки заказа, расценок Перевозчика.</p>
                    <p>8.9.	Покупатель обязан принять товар по количеству и ассортименту в момент его приемки.</p>
                    <p>8.10. При получении товара Покупатель или его Представитель должны в присутствии Перевозчика проверить соответствие полученного товара по наименованию, количеству, ассортименту, качеству, комплектности заказанному товару.</p>
                    <p>8.11. Покупатель или его уполномоченный Представитель при приемке товара подтверждают своей подписью в документе о получении товара, что не имеют претензий к внешнему виду и комплектности товара.</p>

                    <h3>9.	УСЛОВИЯ ВОЗВРАТА И ОБМЕНА ТОВАРА</h3>
                    <p>9.1.	Покупатель вправе отказаться от товара в любое время до его передачи, а после передачи товара - в течение семи дней, в порядке и на условиях, предусмотренных Законом «О защите прав потребителей.</p>
                    <p>9.2.	Возврат Товара осуществляется в соответствии с «Условиями возврата», указанными на сайте в разделе «Возврат и обмен».</p>
                    <p>9.3.	Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара.</p>
                    <p>9.4.	Претензии по качеству товара принимаются Продавцом по электронному адресу step.hsnks@yandex.ru c указанием номера заказа и описанием причины возврата, а также почтовому адресу юридического лица.</p>
                    <p>9.5.	При возврате товара клиент заполняет заявление на возврат с указанием паспортных данных.</p>
                    <p>9.6.	Возврат денежных средств осуществляется в срок не более чем 10 календарных дней со дня предъявления соответствующего требования.</p>
                    <p>9.7.	Денежные средства возвращаются в зависимости от способа оплаты заказа. При оплате заказа с помощью банковской карты на сайте или в терминалах точках самовывоза возврат денежных средств возможен только на банковскую карту, с которой была произведена оплата и только владельцу банковской карты при предъявлении паспорта.</p>
                    <p>9.8.	При возврате товара, бонусные баллы, затраченные на его покупку, возвращаются Продавцом согласно условиям бонусной программы.</p>

                    <h3>10.	АДРЕС И РЕКВИЗИТЫ ПРОДАВЦА</h3>
                    <p>
                        ПРОДАВЕЦ:<br/>
                        ООО "СТЕФСНИКС"<br/>
                        Место нахождения:<br/>
                        119121, Москва г., 1-Й Неопалимовский пер., дом 15/7, офис ЭТАЖ ЦОКОЛЬНЫЙ ПОМЕЩ./КОМ. I/4<br/>
                        ОГРН 1217700190498<br/>
                        ИНН 9704061711 КПП 770401001<br/>
                        р/с 40702810500050000078<br/>
                        в АО "РОССЕЛЬХОЗБАНК" к/с 30101810200000000111<br/>
                        БИК 044525111<br/>
                        step.hsnks@yandex.ru
                    </p>
                </div>
            </section>
        </>
    )
}