export const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <button 
            id="next" 
            type="button" 
            className="next btn btn-juliet"
            onClick={onClick}
        ></button>
    );
}