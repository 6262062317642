import { Link } from 'react-router-dom'
import logo from '../image/logo.png'
import { FooterUL } from './FooterUL'

export const Footer = () => {
    return(
        <>
            <footer>
                <div className="content">
                    <div className="row">
                        <div className="col-md-4 mcenter">
                            <Link to="/">
                                <img src={logo} />
                            </Link>
                            <div className="soc">
                                <Link to="">
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 13.5C0 6.04415 6.04414 0 13.5 0C20.9558 0 26.9999 6.04415 26.9999 13.5C26.9999 20.9558 20.9558 27 13.5 27C6.04414 27 0 20.9558 0 13.5Z" fill="black" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.96777 8.62354C6.09014 14.4969 9.02681 18.0266 14.1754 18.0266H14.4673V14.6663C16.3592 14.8546 17.7898 16.2382 18.3639 18.0266H21.0371C20.3029 15.3535 18.3733 13.8757 17.1685 13.3109C18.3733 12.6144 20.0675 10.9202 20.4723 8.62354H18.0438C17.5167 10.4872 15.9544 12.1815 14.4673 12.3415V8.62354H12.0388V15.137C10.5328 14.7605 8.63149 12.9345 8.54678 8.62354H5.96777Z" fill="white" />
                                    </svg>
                                </Link>
                                <Link to="">
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M-0.00012207 13.5C-0.00012207 6.04415 6.04402 0 13.4998 0C20.9557 0 26.9998 6.04415 26.9998 13.5C26.9998 20.9558 20.9557 27 13.4998 27C6.04402 27 -0.00012207 20.9558 -0.00012207 13.5Z" fill="black" />
                                        <path d="M14.9011 9.45806C13.7142 9.97931 11.342 11.0582 7.78457 12.6946C7.2069 12.9372 6.90429 13.1744 6.87675 13.4065C6.83019 13.7986 7.29525 13.953 7.92854 14.1632L7.92886 14.1633C8.01491 14.1919 8.10406 14.2215 8.19545 14.2529C8.81852 14.4667 9.65665 14.7169 10.0924 14.7268C10.4876 14.7358 10.9287 14.5638 11.4157 14.2107C14.7395 11.8417 16.4553 10.6444 16.563 10.6186C16.639 10.6003 16.7443 10.5774 16.8157 10.6444C16.887 10.7114 16.88 10.8382 16.8725 10.8722C16.8264 11.0796 15.0009 12.8715 14.0562 13.7988L14.0561 13.7989L14.0561 13.7989C13.7616 14.088 13.5527 14.293 13.51 14.3399C13.4143 14.4448 13.3168 14.544 13.2231 14.6394C12.6443 15.2285 12.2102 15.6704 13.2472 16.3919C13.7455 16.7386 14.1442 17.0253 14.5421 17.3113C14.9765 17.6237 15.4098 17.9353 15.9705 18.3233C16.1133 18.4222 16.2497 18.5249 16.3826 18.6249L16.3826 18.6249C16.8882 19.0054 17.3424 19.3473 17.9036 19.2928C18.2297 19.2611 18.5665 18.9374 18.7376 17.9719C19.1418 15.69 19.9365 10.746 20.1201 8.70867C20.1362 8.53017 20.116 8.30173 20.0997 8.20145C20.0834 8.10117 20.0495 7.95829 19.926 7.85253C19.7798 7.72726 19.5541 7.70085 19.4532 7.70273C18.9942 7.71126 18.29 7.96979 14.9011 9.45806Z" fill="white" />
                                    </svg>
                                </Link>
                                <Link to="">
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M-0.000244141 13.5C-0.000244141 6.04415 6.0439 0 13.4997 0C20.9555 0 26.9997 6.04415 26.9997 13.5C26.9997 20.9558 20.9555 27 13.4997 27C6.0439 27 -0.000244141 20.9558 -0.000244141 13.5Z" fill="black"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1792 7.31201H15.8198C17.9552 7.31201 19.687 9.04374 19.687 11.1792V15.8198C19.687 17.9553 17.9552 19.687 15.8198 19.687H11.1792C9.04373 19.687 7.31201 17.9553 7.31201 15.8198V11.1792C7.31201 9.04374 9.04373 7.31201 11.1792 7.31201ZM15.8198 18.5269C17.3125 18.5269 18.5268 17.3126 18.5268 15.8198V11.1792C18.5268 9.68646 17.3125 8.47217 15.8198 8.47217H11.1792C9.68646 8.47217 8.47216 9.68646 8.47216 11.1792V15.8198C8.47216 17.3126 9.68646 18.5269 11.1792 18.5269H15.8198Z" fill="white"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4058 13.4998C10.4058 11.7912 11.791 10.406 13.4995 10.406C15.208 10.406 16.5932 11.7912 16.5932 13.4998C16.5932 15.2083 15.208 16.5935 13.4995 16.5935C11.791 16.5935 10.4058 15.2083 10.4058 13.4998ZM11.5659 13.4998C11.5659 14.5656 12.4337 15.4333 13.4995 15.4333C14.5653 15.4333 15.4331 14.5656 15.4331 13.4998C15.4331 12.4332 14.5653 11.5662 13.4995 11.5662C12.4337 11.5662 11.5659 12.4332 11.5659 13.4998Z" fill="white"/>
                                        <path d="M16.8253 10.586C17.053 10.586 17.2376 10.4014 17.2376 10.1737C17.2376 9.94604 17.053 9.76147 16.8253 9.76147C16.5977 9.76147 16.4131 9.94604 16.4131 10.1737C16.4131 10.4014 16.5977 10.586 16.8253 10.586Z" fill="white"/>
                                    </svg>  
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-3">
                                    <FooterUL 
                                        title="Магазин" 
                                        ul={<>
                                            <ul>
                                                <li>
                                                    <Link to="/catalog/">Новая коллекция</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">Кроссовки</Link>
                                                </li>
                                                
                                                <li>
                                                    <Link to="/catalog/">Толстовки</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">Брюки</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">Футболки</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">Куртки и жилетки</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">Рюкзаки и сумки</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">Для спорта</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">Аксессуары</Link>
                                                </li> 
                                            </ul>  
                                        </>} 
                                    />
                                </div>
                                <div className="col-md-3">
                                    <FooterUL 
                                        title="Бренды" 
                                        ul={<>
                                            <ul>
                                                <li>
                                                    <Link to="/catalog/">STREETBEAT</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">PUMA</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">New Balance</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">Vans</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">Salomon</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">Reebok</Link>
                                                </li>
                                                <li>
                                                    <Link to="/catalog/">On Running</Link>
                                                </li>
                                                <li>
                                                    <Link to="/brands/">Все бренды</Link>
                                                </li>
                                            </ul>
                                        </>}
                                    /> 
                                </div>
                                <div className="col-md-3">
                                    <FooterUL 
                                        title="Помощь" 
                                        ul={<>
                                            <ul>
                                                <li>
                                                    <Link to="/contact/">Контакты</Link>
                                                </li>
                                                <li>
                                                    <Link to="/return/">Способы оплаты</Link>
                                                </li>
                                                <li>
                                                    <Link to="/delivery/">Доставка и самовывоз</Link>
                                                </li>
                                                <li>
                                                    <Link to="/faq/">Частые вопросы</Link>
                                                </li>
                                            </ul>    
                                        </>}
                                    />
                                </div>
                                <div className="col-md-3 footer-contact__wrapper">
                                    <h3>Контакты</h3>
                                    <div className="footer-contact">
                                        <b>Магазин:</b><br/>
                                        Москва, Цветной Бульвар 22
                                    </div>
                                        
                                    <div className="footer-contact">
                                        <b>График работы:</b><br/>
                                        Понедельник - Воскресенье 12:00 - 22:00
                                    </div>

                                    <div className="footer-contact">
                                        <b>Телефон:</b><br/>
                                        <Link to="tel:84959999999">8 (495) 999-99-99</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-underline">
                    <div className="row">
                        <div className="col-md-2">
                            © 2024 Stephsnks
                        </div>
                        <div className="col-md-10 right">
                            <Link to="/politika/">Положение по работе с персональными данными</Link>
                            <Link to="/oferta/">Договор-оферта</Link>
                            <Link to="/obmen/">Положение о возврате и обмене товара</Link>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}