import { useState } from "react"
import { Link } from "react-router-dom"

export const FooterUL = (props: any) => {
    const [active, setActive] = useState(false)
    return(
        <>
            <div className={`nav-wrapper ${active ? "active":""}`}>
                <h3 onClick={()=>{setActive(!active)}}>{props.title}</h3>
                {props.ul}
            </div>
        </>
    )
}