import { useState } from "react"

export const CatalogFilter = (props: any) => {
    const [showAll, setShowAll] = useState(false)
    return(
        <>
            <div className={`catalog-filter__cat ${showAll ? "active":""}`}>
                <h3 onClick={()=>{setShowAll(!showAll)}}>{props.title}</h3>
                {
                    showAll ? (
                        <ul>
                            {
                                props.list.length ? (
                                    props.list.map((item: any, index: number) => {
                                        return(
                                            <li value={item.pk != -1 ? item.pk : item.title}>
                                                <span 
                                                    className={`${props.selected.indexOf(item.pk) >= 0 || props.selected.indexOf(item.title) >= 0 ? "active":""}`}
                                                    onClick={()=>{
                                                        let flag = false
                                                        const array = []
                                                            props.selected.forEach((item2: any) => {
                                                                if(item.pk != -1)
                                                                    if(item2 == item.pk)
                                                                        flag = true 
                                                                    else 
                                                                        array.push(item2)
                                                                else
                                                                    if(item2 == item.title)
                                                                        flag = true 
                                                                    else 
                                                                        array.push(item2)
                                                            })
    
                                                            if(!flag)
                                                                if(item.pk != -1)
                                                                    array.push(item.pk)
                                                                else
                                                                    array.push(item.title)
 
                                                        props.setSelected(array)
                                                        console.log(array)
                                                    }}
                                                >
                                                    {
                                                        item.rgb ? (
                                                            <div className="color-circle" style={{backgroundColor: `${item.rgb }`}}></div>
                                                        ):""
                                                    }
                                                    {item.title}
                                                </span>
                                            </li>
                                        )
                                    })   
                                ):""
                            }
                        </ul>
                    ):""
                }
            </div>
        </>
    )
}