import React from 'react';


import './style/bootstrap-grid.css';
import './style/style.css';

import { Route, Routes } from 'react-router-dom';
import { useMyRoutes } from './routes';
import { Layout } from './layouts/layout';
import { Header } from './component/Header';
import { Footer } from './component/Footer';
import ScrollToTop from './component/ScrollToTop';
import { User } from './classes';
import { GlobalContext } from './context/GlobalContext';

function App() {
    const routes = useMyRoutes() 
    return (
        <>
        <GlobalContext.Provider value={{
            user: new User,
        }}> 
            <ScrollToTop />
            <Header />
            <Routes>
                <Route element={<Layout />}>{routes}</Route>
            </Routes> 
            <Footer />
            </GlobalContext.Provider>
        </>
  );
}

export default App;