import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Catalog } from "../classes"
import { ProductInList } from "../component/ProductInList"
import { Pagination } from "../component/Pagination"
import { GlobalContext } from "../context/GlobalContext"
import { Loader } from "../component/Loader"

export const FavoritePage = () => {
    const auth = useContext(GlobalContext)
    const [catalog, setCatalog] = useState(new Catalog())
    const [load, setLoad] = useState(true) 
    
    const LoadProducts = (page: number = 1) => {
        setLoad(true)
        catalog.LoadFavorite(page, auth.user.favourites).then(function(response){
            setLoad(false)
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }

    useEffect(()=>{
        LoadProducts()
    },[])

    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <section className="catalog">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>Избранное</span>
                    </div>
                    <h1 className="title">Избранное</h1>   

                    <div className="catalog-wrapper" style={{marginTop: 0, display: "block"}}> 
                        <div className="catalog-space"> 
                            <div className="row">
                                {
                                    catalog.products.length ? (
                                        catalog.products.map((item, index) => {
                                            return(
                                                <div className="col-md-6 col-lg-five">
                                                    <ProductInList key={index} data={item} />
                                                </div>
                                            )
                                        })
                                    ):""
                                }
                            </div>
                        
                            {
                                catalog.pageCount > 1 ? (
                                    <Pagination
                                        page={catalog.page}
                                        pageCount={catalog.pageCount}
                                        LoadProducts={LoadProducts}
                                    />
                                ):""
                            }
                        </div>
                    </div> 

                </div>
            </section>
        </>
    )
}