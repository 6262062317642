import { Route } from "react-router-dom"
import { useContext, useEffect } from "react"
import { MainPage } from "./pages/MainPage"
import { BrandPage } from "./pages/Brand/BrandPage"
import { BrandItemPage } from "./pages/Brand/BrandItemPage"
import { DeliveryPage } from "./pages/DeliveryPage"
import { FAQPage } from "./pages/FAQPage"
import { ReturnPage } from "./pages/ReturnPage"
import { BlogPage } from "./pages/Blog/BlogPage"
import { BlogItemPage } from "./pages/Blog/BlogItemPage"
import { ContactPage } from "./pages/ContactPage"
import { CatalogPage } from "./pages/CatalogPage"
import { LoginPage } from "./pages/LoginPage"
import { ProductPage } from "./pages/ProductPage"
import { PasswordPage } from "./pages/PasswordPage"
import { RegistrationPage } from "./pages/RegistrationPage"
import { CartPage } from "./pages/CartPage"
import { GlobalContext } from "./context/GlobalContext"
import { FavoritePage } from "./pages/FavoritePage"
import { CompilationsPage } from "./pages/CompilationsPage"
import { AfterOrderPage } from "./pages/AfterOrderPage"
import { AccountPage } from "./pages/Account/AccountPage"
import { AccountOrderPage } from "./pages/Account/AccountOrderPage"
import { AccountSettingPage } from "./pages/Account/AccountSettingPage"
import { AboutPage } from "./pages/AboutPage"
import { OfertaPage } from "./pages/OfertaPage"
import { PolitikaPage } from "./pages/PolitikaPage"
import { ObmenPage } from "./context/ObmenPage"
import { Page404 } from "./pages/Page404"

export const useMyRoutes = () => { 
    const auth = useContext(GlobalContext)
    
    return (
        <>
            <Route path={`/`} element={<MainPage/>} />
            <Route path={`/delivery/`} element={<DeliveryPage/>} />
            <Route path={`/faq/`} element={<FAQPage/>} />
            <Route path={`/return/`} element={<ReturnPage/>} />
            <Route path={`/contact/`} element={<ContactPage/>} />
            
            
            <Route path={`/about/`} element={<AboutPage/>} />
            
            <Route path={`/login/`} element={<LoginPage/>} />
            <Route path={`/password/`} element={<PasswordPage/>} />
            <Route path={`/registration/`} element={<RegistrationPage/>} />

            <Route path={`/account/`} element={<AccountPage/>} />
            <Route path={`/account/setting/`} element={<AccountSettingPage/>} />
            <Route path={`/account/:id/`} element={<AccountOrderPage/>} />

            
            <Route path={`/compilations/:slug/`} element={<CompilationsPage/>} />
             
            <Route path={`/favorites/`} element={<FavoritePage/>} />

            <Route path={`/order-conf/`} element={<AfterOrderPage/>} />
            
            <Route path={`/catalog/`} element={<CatalogPage/>} />
            <Route path={`/catalog/:type/`} element={<CatalogPage/>} />
            
            <Route path={`/product/:slug/`} element={<ProductPage/>} />

            <Route path={`/cart/`} element={<CartPage/>} />
            <Route path={`/blog/`} element={<BlogPage/>} />
            <Route path={`/blog/:slug/`} element={<BlogItemPage/>} />
            <Route path={`/brands/`} element={<BrandPage/>} />
            <Route path={`/brands/:slug/`} element={<BrandItemPage/>} />

            

            <Route path={`/oferta/`} element={<OfertaPage/>} />
            <Route path={`/politika/`} element={<PolitikaPage/>} />
            <Route path={`/obmen/`} element={<ObmenPage/>} />
            

            <Route path={`*`} element={<Page404/>} />
             
        </>
    )
}