import { ChangeEvent, useEffect, useState } from "react"
import InputMask from "react-input-mask";
import axios from "axios"
import { host } from "../classes";
import { AnswerBox } from "./AnswerBox";



export const FooterForm = () => {
    const [load, setLoad] = useState(false)
    const [answerShow, setAnswerShow] = useState(false)
    const [answerText, setAnswerText] = useState("")

    const [form, setForm] = useState({
        name: "",
        phone: "",
        msg: ""
    })

    useEffect(()=>{
        if(answerShow == true && answerText != ""){
            setTimeout(()=>{
                setAnswerShow(false)
            }, 5000);
        }
    },[answerShow])

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
 
        if(!load){
            setLoad(true)
            await axios.post(`${host}contact-form/`, {
                name: form.name,
                phone: form.phone,
                msg: form.msg
            },{
                headers: {
                    'Content-Type': 'application/json',
    
                }
            }).then(res => { 
                setForm({name: "", phone: "", msg: ""})
                setLoad(false)
                setAnswerShow(true)
                setAnswerText("Ваша заявка успешно отправлена")
            }).catch(function (error) { 
                setForm({name: "", phone: "", msg: ""})
                setLoad(false)
                console.log(error)
            }) 
        }
    }

    return(
        <>
            <section className="cform">
                <div className="content">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Остались вопросы?</h2>
                            <p>
                                Мы рады ответить на любые вопросы ежедневно<br/>
                                <b>с 9:00 до 22:00</b>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="text" required placeholder="Имя" name="name" value={form.name} onChange={changeHandler} />
                                    </div>
                                    <div className="col-md-6">
                                        <InputMask mask="+7 (999) 999-99-99" onChange={changeHandler} name="phone" placeholder="Номер телефона" value={form.phone} />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea required placeholder="Текст сообщения" name="msg" value={form.msg} onChange={(e)=>{
                                            setForm({ ...form, "msg":e.target.value })
                                        }}></textarea>
                                    </div>
                                    <div className="col-md-8">
                                        <label>
                                            <input required type="checkbox" />
                                            <span>Нажимая на кнопку "Оставить заявку",  я соглашаюсь с условиями <a href="">Политики конфиденциальности</a></span>
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <button>Оставить заявку</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            {
                answerShow ? (
                    <AnswerBox text={answerText} />
                ):""
            }
        </>
    )
}