import { Link, useParams, useSearchParams } from "react-router-dom"
import logo from '../image/logo.png'
import sale from '../image/sale.svg'
import { useContext, useEffect, useRef, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"

export const Header = () => {
    const auth = useContext(GlobalContext)
    
    const [showMenu, setShowMenu] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [shopNotification, setShopNotification] = useState(false) 

    const [searchParams, setSearchParams] = useSearchParams();
    const [searchText, setSearchText] = useState(searchParams.get("s") ? searchParams.get("s") : "")
    
    const [cartCount, setCartCount] = useState(auth.user.cart.productCount)
    useEffect(()=>{
        auth.user.cart.GetToken(auth.user.token).then(function(response){
            console.log(auth.user.cart.token)
        }).catch(function(error){
            alert("error")
        })

        const interval = setInterval(() => {
            setCartCount(auth.user.cart.productCount)
        }, 1000);

    },[])

    useEffect(()=>{
        setCartCount(auth.user.cart.productCount)
    },[auth.user.cart.productCount])

    return(
        <>
            <header>
                <div className="top-line">
                    <div className="content">
                        <div className="row">
                            <div className="col-6 col-md-4">
                                <div className="city-list">
                                    Москва и область
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="8" cy="7.33333" r="1.33333" stroke="white" />
                                        <path d="M7.99999 14.6667L11.6667 11C13.6917 8.97496 13.6917 5.69171 11.6667 3.66667C9.64162 1.64163 6.35837 1.64163 4.33333 3.66667C2.30828 5.69171 2.30828 8.97496 4.33333 11L7.99999 14.6667Z" stroke="white" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <div className="hmob col-md-4">
                                <ul>
                                    <li>
                                        <Link to="/delivery/">Доставка и оплата</Link>
                                    </li>
                                    <li>
                                        <Link to="/return/">Обмен и возврат</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact/">Контакты</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 col-md-4 md-right">
                                <Link to="tel:84959999999">8 (495) 999-99-99</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu">
                    <div className="content">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="grid lmenu">
                                    <ul>
                                        <li>
                                            <Link to={`/catalog/`}>
                                                Каталог
                                            </Link>    
                                            <div className="row lvl2-menu">
                                                <div className="col-lg-4">
                                                    <Link to={`/catalog/`}>Новинки</Link>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Link to={`/catalog/`}>Выбор Stephsnks</Link>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Link to={`/catalog/`}>Одежда</Link>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Link to={`/catalog/`}>Обувь</Link>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Link to={`/catalog/`}>Аксессуары</Link>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Link to={`/catalog/`}>Искусство</Link>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Link to={`/catalog/`}>Для дома</Link>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Link to="/catalog/">
                                                        <img src={sale} />
                                                        Sale
                                                    </Link>
                                                </div>
                                            </div>
                                        </li> 
                                        <li>
                                            <Link to={`/about/`}>⁠О нас</Link>
                                        </li>
                                        <li>
                                            <Link to={`/brands/`}>⁠Бренды</Link>
                                        </li>
                                        <li>
                                            <Link to={`/`}>Stephsnks concierge</Link>
                                        </li>
                                        <li>
                                            <Link to={`/faq/`}>FAQ</Link>
                                            <div className="row lvl2-menu" style={{width: "450px"}}>
                                                <div className="col-md-6">
                                                    <Link to={`/delivery/`}>Доставка и оплата</Link>
                                                </div>
                                                <div className="col-md-6">
                                                    <Link to={`/return/`}>Обмен и возврат</Link>
                                                </div>
                                                <div className="col-md-4" style={{display: "none"}}>
                                                    <Link to={``}>Программа лояльности</Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <Link to={`/contact/`}>Контакты</Link>
                                        </li>
                                        <li>
                                            <Link to={`/blog/`}>⁠Блог</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-2 center">
                                <Link to="/">
                                    <img src={logo} />
                                </Link>
                            </div>
                            <div className="col-md-5">
                                <div className="grid rmenu">
                                    <ul>
                                        <li>
                                            <div onClick={()=>{setShowSearch(true)}}>
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.75 15.75L12.4926 12.4926M12.4926 12.4926C13.5784 11.4069 14.25 9.90685 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25C9.90685 14.25 11.4069 13.5784 12.4926 12.4926Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Поиск</span>
                                            </div>
                                        </li>
                                        <li>
                                            <Link to="/account/">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.25 14.25C5.25 13.0074 6.92893 12 9 12C11.0711 12 12.75 13.0074 12.75 14.25" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Личный кабинет</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/favorites/">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.709 4.06089C14.3792 3.72455 13.9875 3.45775 13.5564 3.27572C13.1254 3.09369 12.6633 3 12.1967 3C11.7301 3 11.2681 3.09369 10.837 3.27572C10.4059 3.45775 10.0143 3.72455 9.68441 4.06089L8.99983 4.75857L8.31524 4.06089C7.64894 3.38184 6.74523 3.00035 5.80294 3.00035C4.86064 3.00035 3.95694 3.38184 3.29063 4.06089C2.62433 4.73994 2.25 5.66092 2.25 6.62124C2.25 7.58157 2.62433 8.50255 3.29063 9.1816L3.97522 9.87928L8.99983 15L14.0244 9.87928L14.709 9.1816C15.039 8.84543 15.3008 8.44628 15.4795 8.00697C15.6581 7.56765 15.75 7.09678 15.75 6.62124C15.75 6.14571 15.6581 5.67484 15.4795 5.23552C15.3008 4.79621 15.039 4.39706 14.709 4.06089V4.06089Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Избранное</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/cart/">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 8.25V6C6 4.34315 7.34315 3 9 3C10.6569 3 12 4.34315 12 6V8.25" stroke="black" stroke-linecap="round" />
                                                    <path d="M2.5715 8.51833C2.40409 7.59757 3.11145 6.75 4.04731 6.75H13.9527C14.8885 6.75 15.5959 7.59757 15.4285 8.51833L14.4739 13.7683C14.3443 14.4816 13.7231 15 12.9981 15H5.00185C4.27692 15 3.65573 14.4816 3.52605 13.7683L2.5715 8.51833Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Корзина ({cartCount})</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="underline" style={{display: "none"}}>
                    <div className="content">
                        <ul>
                            <li>
                                <Link to="">Jordan brand</Link>
                            </li>
                            <li>
                                <Link to="">Dunk Low</Link>
                            </li>
                            <li>
                                <Link to="">Nike</Link>
                            </li>
                            <li>
                                <Link to="">Adidas</Link>
                            </li>
                            <li>
                                <Link to="">New Balance</Link>
                            </li>
                            <li>
                                <Link to="">
                                    <img src={sale} />
                                    Sale
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>

            <div className={`search-block ${showSearch ? "active":""}`}>
                <form action={`/catalog/`}>
                    <input 
                        type="text" 
                        name="s" 
                        value={searchText ? searchText: ""} 
                        onChange={(e)=>{
                            setSearchText(e.target.value)
                        }} 
                        placeholder="Поиск..." 
                    />
                    <button>
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.9604 11.4802C19.9604 13.8094 19.0227 15.9176 17.5019 17.4512C16.9332 18.0247 16.2834 18.5173 15.5716 18.9102C14.3594 19.5793 12.9658 19.9604 11.4802 19.9604C6.79672 19.9604 3 16.1637 3 11.4802C3 6.79672 6.79672 3 11.4802 3C16.1637 3 19.9604 6.79672 19.9604 11.4802Z" stroke="#333333" stroke-width="2"/>
                            <path d="M18.1553 18.1553L21.8871 21.8871" stroke="#333333" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                    </button>
                </form>
            </div>

            <svg onClick={()=>{setShowMenu(true)}} className="openMobMenu" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Menu / Menu_Alt_01">
                    <path id="Vector" d="M12 17H19M5 12H19M5 7H19" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
            </svg>

            <div className={`mobmenu ${showMenu ? "active":""}`}>
                <div className="close">
                    <svg onClick={()=>{setShowMenu(false)}} width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                    </svg>
                </div>
                <ul>
                    <li>
                        <Link to="">Магазины</Link>
                    </li>
                    <li>
                        <Link to="">Доставка и оплата</Link>
                    </li>
                    <li>
                        <Link to="">Обмен и возврат</Link>
                    </li>
                    <li>
                        <Link to="">Контакты</Link>
                    </li>
                    <li>
                        <Link to="">Обувь</Link>
                    </li>
                    <li>
                        <Link to="">Одежда</Link>
                    </li>
                    <li>
                        <Link to="">Аксессуары</Link>
                    </li>
                    <li>
                        <Link to="">Бренды</Link>
                    </li>
                    <li>
                        <Link to="">Блог</Link>
                    </li>
                </ul>
            </div>
        </>
    )
}