import { ChangeEvent, useContext, useState } from "react"
import { Link } from "react-router-dom"
import { GlobalContext } from "../context/GlobalContext"
import { AnswerBox } from "../component/AnswerBox"

export const PasswordPage = () => {
    const auth = useContext(GlobalContext)
    if(auth.user.token != ""){ window.location.replace("/account/") }

    const [query, setQuery] = useState(false)
    const [answerShow, setAnswerShow] = useState(false)
    const [answerText, setAnswerText] = useState("")

    const [form, setForm] = useState({ 
        email: "",
    })
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(!query){
            auth.user.SendPassword(form.email).then(function(response){
                setForm({
                    email: "",
                })
                setAnswerShow(true)
                setAnswerText(response.msg)
            }).catch(function(error){
                alert("error")
            })
        }
    }

    return(
        <>
            <section className="login">
                <div className="content">
                    <div className="login-wrapper">
                        <form className="form-page" onSubmit={handleSubmit}>
                            <h1 className="title">Восстановление пароля</h1>
                            <p>Укажите ваш Email для Восстановления пароля</p>
                            <label>
                                Email
                                <input type="email" required name="email" value={form.email} onChange={changeHandler} />
                            </label> 
                            <div className="btn-link">
                                <button>Отправить</button>
                                <Link to="">Войти</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            {
                answerShow ? (
                    <AnswerBox text={answerText} />
                ):""
            }
        </>
    )
}