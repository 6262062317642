import { Link } from "react-router-dom"
import { Loyalty } from "../component/Loyalty"
import { FooterForm } from "../component/FooterForm"
import shoes_img from "../image/shoes_img.png"
import shoes_img2 from "../image/shoes_img2.png" 
import { useEffect, useState } from "react"
import { ProductSlider } from "../component/ProductSlider"

export const DeliveryPage = () => {
    const [targetItem, setTargetItem] = useState(0)
    const [produtList, setProductList] = useState([
        {
            sticker: "Desc",
            stickerColor: "var(--red)",
            image: shoes_img,
            secondImage: shoes_img2,
            sizes: [
                {
                    name: "36",
                    available: false
                },
                {
                    name: "37",
                    available: true
                },
                {
                    name: "38",
                    available: true
                }
            ],
            title: "Jordan Air Jordan 1 Low ‘Black and Dark Powder Blue’",
            brand: "NIKE",
            cost: 1000,
            descCost: 999,
            slug: "/"
        },
        {
            sticker: "Sale",
            stickerColor: "var(--red)",
            image: shoes_img,
            secondImage: shoes_img2,
            sizes: [
                {
                    name: "36",
                    available: false
                },
                {
                    name: "37",
                    available: true
                },
                {
                    name: "38",
                    available: true
                }
            ],
            title: "Jordan Air Jordan 1 Low ‘Black and Dark Powder Blue’",
            brand: "NIKE",
            cost: 1000,
            descCost: 999,
            slug: "/"
        },
        {
            sticker: "Sale",
            stickerColor: "var(--red)",
            image: shoes_img,
            secondImage: shoes_img2,
            sizes: [
                {
                    name: "36",
                    available: false
                },
                {
                    name: "37",
                    available: true
                },
                {
                    name: "38",
                    available: true
                }
            ],
            title: "Jordan Air Jordan 1 Low ‘Black and Dark Powder Blue’",
            brand: "NIKE",
            cost: 1000,
            descCost: 999,
            slug: "/"
        },
        {
            sticker: "Sale",
            stickerColor: "var(--red)",
            image: shoes_img,
            secondImage: shoes_img2,
            sizes: [
                {
                    name: "36",
                    available: false
                },
                {
                    name: "37",
                    available: true
                },
                {
                    name: "38",
                    available: true
                }
            ],
            title: "Jordan Air Jordan 1 Low ‘Black and Dark Powder Blue’",
            brand: "NIKE",
            cost: 1000,
            descCost: 999,
            slug: "/"
        },
        {
            sticker: "Sale",
            stickerColor: "var(--red)",
            image: shoes_img,
            secondImage: shoes_img2,
            sizes: [
                {
                    name: "36",
                    available: false
                },
                {
                    name: "37",
                    available: true
                },
                {
                    name: "38",
                    available: true
                }
            ],
            title: "Jordan Air Jordan 1 Low ‘Black and Dark Powder Blue’",
            brand: "NIKE",
            cost: 1000,
            descCost: 999,
            slug: "/"
        },
        {
            sticker: "Sale",
            stickerColor: "var(--red)",
            image: shoes_img,
            secondImage: shoes_img2,
            sizes: [
                {
                    name: "36",
                    available: false
                },
                {
                    name: "37",
                    available: true
                },
                {
                    name: "38",
                    available: true
                }
            ],
            title: "Jordan Air Jordan 1 Low ‘Black and Dark Powder Blue’",
            brand: "NIKE",
            cost: 1000,
            descCost: 999,
            slug: "/"
        }
    ])

    const menuList = [
        {
            name: "Доставка",
            text: <>
                <p>Наше дело не так однозначно, как может показаться: выбранный нами инновационный путь требует от нас анализа как самодостаточных, так и внешне зависимых концептуальных решений. В целом, конечно, семантический разбор внешних противодействий требует от нас анализа позиций, занимаемых участниками в отношении поставленных задач. Учитывая ключевые сценарии поведения, дальнейшее развитие различных форм деятельности предполагает независимые способы реализации первоочередных требований.</p>
                <p>Как принято считать, действия представителей оппозиции подвергнуты целой серии независимых исследований. Предварительные выводы неутешительны: семантический разбор внешних противодействий способствует подготовке и реализации своевременного выполнения сверхзадачи. Прежде всего, экономическая повестка сегодняшнего дня играет важную роль в формировании новых принципов формирования материально-технической и кадровой базы. С другой стороны, базовый вектор развития позволяет оценить значение позиций, занимаемых участниками в отношении поставленных задач! Безусловно, разбавленное изрядной долей эмпатии, рациональное мышление выявляет срочную потребность прогресса профессионального сообщества.</p>
                <p>Но сплочённость команды профессионалов однозначно фиксирует необходимость позиций, занимаемых участниками в отношении поставленных задач. Учитывая ключевые сценарии поведения, дальнейшее развитие различных форм деятельности прекрасно подходит для реализации экспериментов, поражающих по своей масштабности и грандиозности. Не следует, однако, забывать, что глубокий уровень погружения влечет за собой процесс внедрения и модернизации новых предложений. Как уже неоднократно упомянуто, некоторые особенности внутренней политики своевременно верифицированы. Также как современная методология разработки предоставляет широкие возможности для переосмысления внешнеэкономических политик. Следует отметить, что синтетическое тестирование не оставляет шанса для прогресса профессионального сообщества.</p>
                <p>В частности, новая модель организационной деятельности позволяет оценить значение экспериментов, поражающих по своей масштабности и грандиозности. Прежде всего, высокое качество позиционных исследований является качественно новой ступенью глубокомысленных рассуждений.</p>
            </>
        },
        {
            name: "Оплата",
            text: <>
                <p>Как принято считать, действия представителей оппозиции подвергнуты целой серии независимых исследований. Предварительные выводы неутешительны: семантический разбор внешних противодействий способствует подготовке и реализации своевременного выполнения сверхзадачи. Прежде всего, экономическая повестка сегодняшнего дня играет важную роль в формировании новых принципов формирования материально-технической и кадровой базы. С другой стороны, базовый вектор развития позволяет оценить значение позиций, занимаемых участниками в отношении поставленных задач! Безусловно, разбавленное изрядной долей эмпатии, рациональное мышление выявляет срочную потребность прогресса профессионального сообщества.</p>
                <p>Но сплочённость команды профессионалов однозначно фиксирует необходимость позиций, занимаемых участниками в отношении поставленных задач. Учитывая ключевые сценарии поведения, дальнейшее развитие различных форм деятельности прекрасно подходит для реализации экспериментов, поражающих по своей масштабности и грандиозности. Не следует, однако, забывать, что глубокий уровень погружения влечет за собой процесс внедрения и модернизации новых предложений. Как уже неоднократно упомянуто, некоторые особенности внутренней политики своевременно верифицированы. Также как современная методология разработки предоставляет широкие возможности для переосмысления внешнеэкономических политик. Следует отметить, что синтетическое тестирование не оставляет шанса для прогресса профессионального сообщества.</p>
                <p>В частности, новая модель организационной деятельности позволяет оценить значение экспериментов, поражающих по своей масштабности и грандиозности. Прежде всего, высокое качество позиционных исследований является качественно новой ступенью глубокомысленных рассуждений.</p>
            </>
        }
    ]

    return(
        <>
            <section className="delivery">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>Доставка и оплата</span>
                    </div>
                    <h1 className="title">Доставка и оплата</h1>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="delivery-type">
                                {
                                    menuList.map((item, index) => {
                                        return(
                                            <div onClick={()=>{setTargetItem(index)}} className={`${index == targetItem ? "active":""}`}>
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="8" width="15" height="1" rx="0.5" transform="rotate(90 8 0)" fill="#101011" />
                                                    <rect y="7" width="15" height="1" rx="0.5" fill="#101011" />
                                                </svg>
                                                {item.name}
                                            </div>
                                        )
                                    })
                                } 
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="delivery-body">
                                {
                                    menuList[targetItem].text
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Loyalty />
            <FooterForm />
        </>
    )
}