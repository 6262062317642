import { ChangeEvent, useEffect, useState } from "react"
import infobg from "../image/info-bg.png"
import axios from "axios"
import { AnswerBox } from "./AnswerBox"
import { host } from "../classes"

export const InfoBlock = () => {
    const [load, setLoad] = useState(false)
    const [answerShow, setAnswerShow] = useState(false)
    const [answerText, setAnswerText] = useState("")
    const [form, setForm] = useState({
        email: ""
    })

    useEffect(()=>{
        if(answerShow == true && answerText != ""){
            setTimeout(()=>{
                setAnswerShow(false)
            }, 5000);
        }
    },[answerShow])
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
 
        if(!load){
            setLoad(true)

            await axios.post(`${host}subscribe/`, {
                email: form.email,
            },{
                headers: {
                    'Content-Type': 'application/json',
    
                }
            }).then(res => { 
                setForm({email: ""})
                setLoad(false)
                setAnswerShow(true)
                setAnswerText("Ваша подписка успешно оформлена")
            }).catch(function (error) { 
                setForm({email: ""})
                setLoad(false)
                console.log(error)
            }) 
        }
    }
    
    return(
        <>
            <section className="info-delivery">
                <div className="content" style={{paddingBottom: 0}}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="info-delivery__wrapper">
                                <div className="info-delivery__icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.2001 8.99984L7.44006 3.83984" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M21.6 16.3208V7.67907C21.5996 7.3002 21.5009 6.92811 21.3137 6.6001C21.1264 6.27209 20.8573 5.99971 20.5334 5.81028L13.0667 1.4894C12.7424 1.29978 12.3745 1.19995 12 1.19995C11.6255 1.19995 11.2577 1.29978 10.9334 1.4894L3.46669 5.81028C3.1427 5.99971 2.8736 6.27209 2.68638 6.6001C2.49916 6.92811 2.40041 7.3002 2.40002 7.67907V16.3208C2.40041 16.6997 2.49916 17.0718 2.68638 17.3998C2.8736 17.7278 3.1427 18.0002 3.46669 18.1896L10.9334 22.5105C11.2577 22.7001 11.6255 22.8 12 22.8C12.3745 22.8 12.7424 22.7001 13.0667 22.5105L20.5334 18.1896C20.8573 18.0002 21.1264 17.7278 21.3137 17.3998C21.5009 17.0718 21.5996 16.6997 21.6 16.3208Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.59998 7.19995L12 12L20.4 7.19995" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M12 22.8V12" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <h2>Доставка по Москве</h2>
                                
                                <h3>От 500 ₽</h3>
                                <p>Стандартная доставка от 2 дней</p>

                                <h3>Бесплатно</h3>
                                <p>Самовывоз из ближайшего магазина, если товар представлен в магазинах вашего города.</p>

                                <h3>800 ₽</h3>
                                <p>Срочная доставка день в день (в пределах МКАД)</p> 
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="info-delivery__wrapper">
                                <div className="info-delivery__icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.1818 2.18188L3.27271 6.10916V19.8546C3.27271 20.3754 3.47703 20.8749 3.84074 21.2431C4.20445 21.6114 4.69774 21.8182 5.2121 21.8182H18.7879C19.3022 21.8182 19.7955 21.6114 20.1592 21.2431C20.5229 20.8749 20.7272 20.3754 20.7272 19.8546V6.10916L17.8182 2.18188H6.1818Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.27271 6.54541H20.7272" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.3637 10.9092C16.3637 12.0665 15.904 13.1764 15.0857 13.9947C14.2673 14.8131 13.1574 15.2728 12.0001 15.2728C10.8428 15.2728 9.73289 14.8131 8.91455 13.9947C8.09621 13.1764 7.63647 12.0665 7.63647 10.9092" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <h2>Бесплатная примерка</h2>
                                <p>При заказе доставки по Москве и за МКАД (до бетонного кольца) ты можешь заказать два размера одного товара для примерки и выбрать тот, что подошёл.</p>
                                <img src={infobg} width="100%" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="info-delivery__wrapper">
                                <div className="info-delivery__icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M2 12H22" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <h2>Подпишись на рассылку</h2>
                                <p>Будьте в курсе всех раcпродаж, акций и новостей! Мы будем присылать вам самое важное и интересное примерно раз в неделю:</p>
                                <form onSubmit={handleSubmit}>
                                    <input required type="email" placeholder="Email" value={form.email} name="email" onChange={changeHandler} />
                                    <button>Подписаться</button>
                                </form>
                                <p>
                                    Подписываясь на рассылку, вы соглашаетесь с <a href="">условиями оферты</a> и <a href="">политики конфиденциальности</a>
                                </p>
                                <div className="info-delivery__soc">
                                    <div>
                                        <a href="" target="_blank">
                                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 13.5C0 6.04415 6.04414 0 13.5 0C20.9558 0 26.9999 6.04415 26.9999 13.5C26.9999 20.9558 20.9558 27 13.5 27C6.04414 27 0 20.9558 0 13.5Z" fill="black" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.96777 8.62354C6.09014 14.4969 9.02681 18.0266 14.1754 18.0266H14.4673V14.6663C16.3592 14.8546 17.7898 16.2382 18.3639 18.0266H21.0371C20.3029 15.3535 18.3733 13.8757 17.1685 13.3109C18.3733 12.6144 20.0675 10.9202 20.4723 8.62354H18.0438C17.5167 10.4872 15.9544 12.1815 14.4673 12.3415V8.62354H12.0388V15.137C10.5328 14.7605 8.63149 12.9345 8.54678 8.62354H5.96777Z" fill="white" />
                                            </svg>
                                        </a>
                                        <a href="" target="_blank">
                                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 13.5C0 6.04415 6.04414 0 13.5 0C20.9558 0 26.9999 6.04415 26.9999 13.5C26.9999 20.9558 20.9558 27 13.5 27C6.04414 27 0 20.9558 0 13.5Z" fill="black" />
                                                <path d="M14.9013 9.45806C13.7144 9.97931 11.3422 11.0582 7.78482 12.6946C7.20715 12.9372 6.90454 13.1744 6.87699 13.4065C6.83043 13.7986 7.29549 13.953 7.92879 14.1632L7.92911 14.1633C8.01515 14.1919 8.1043 14.2215 8.1957 14.2529C8.81876 14.4667 9.65689 14.7169 10.0926 14.7268C10.4878 14.7358 10.929 14.5638 11.416 14.2107C14.7398 11.8417 16.4556 10.6444 16.5633 10.6186C16.6393 10.6003 16.7446 10.5774 16.8159 10.6444C16.8873 10.7114 16.8803 10.8382 16.8727 10.8722C16.8266 11.0796 15.0011 12.8715 14.0564 13.7988L14.0564 13.7989L14.0563 13.7989C13.7618 14.088 13.553 14.293 13.5102 14.3399C13.4146 14.4448 13.3171 14.544 13.2234 14.6394C12.6445 15.2285 12.2105 15.6704 13.2474 16.3919C13.7457 16.7386 14.1445 17.0253 14.5423 17.3113C14.9767 17.6237 15.4101 17.9353 15.9707 18.3233C16.1136 18.4222 16.25 18.5249 16.3828 18.6249L16.3829 18.6249C16.8884 19.0054 17.3427 19.3473 17.9038 19.2928C18.2299 19.2611 18.5668 18.9374 18.7378 17.9719C19.1421 15.69 19.9367 10.746 20.1204 8.70867C20.1364 8.53017 20.1162 8.30173 20.0999 8.20145C20.0837 8.10117 20.0497 7.95829 19.9263 7.85253C19.7801 7.72726 19.5544 7.70085 19.4534 7.70273C18.9944 7.71126 18.2902 7.96979 14.9013 9.45806Z" fill="white" />
                                            </svg>
                                        </a>
                                        <a href="" target="_blank">
                                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M-0.000244141 13.5C-0.000244141 6.04415 6.0439 0 13.4997 0C20.9555 0 26.9997 6.04415 26.9997 13.5C26.9997 20.9558 20.9555 27 13.4997 27C6.0439 27 -0.000244141 20.9558 -0.000244141 13.5Z" fill="black"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1792 7.31201H15.8198C17.9552 7.31201 19.687 9.04374 19.687 11.1792V15.8198C19.687 17.9553 17.9552 19.687 15.8198 19.687H11.1792C9.04373 19.687 7.31201 17.9553 7.31201 15.8198V11.1792C7.31201 9.04374 9.04373 7.31201 11.1792 7.31201ZM15.8198 18.5269C17.3125 18.5269 18.5268 17.3126 18.5268 15.8198V11.1792C18.5268 9.68646 17.3125 8.47217 15.8198 8.47217H11.1792C9.68646 8.47217 8.47216 9.68646 8.47216 11.1792V15.8198C8.47216 17.3126 9.68646 18.5269 11.1792 18.5269H15.8198Z" fill="white"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4058 13.4998C10.4058 11.7912 11.791 10.406 13.4995 10.406C15.208 10.406 16.5932 11.7912 16.5932 13.4998C16.5932 15.2083 15.208 16.5935 13.4995 16.5935C11.791 16.5935 10.4058 15.2083 10.4058 13.4998ZM11.5659 13.4998C11.5659 14.5656 12.4337 15.4333 13.4995 15.4333C14.5653 15.4333 15.4331 14.5656 15.4331 13.4998C15.4331 12.4332 14.5653 11.5662 13.4995 11.5662C12.4337 11.5662 11.5659 12.4332 11.5659 13.4998Z" fill="white"/>
                                                <path d="M16.8253 10.586C17.053 10.586 17.2376 10.4014 17.2376 10.1737C17.2376 9.94604 17.053 9.76147 16.8253 9.76147C16.5977 9.76147 16.4131 9.94604 16.4131 10.1737C16.4131 10.4014 16.5977 10.586 16.8253 10.586Z" fill="white"/>
                                            </svg>    
                                        </a>
                                    </div>
                                    <p>
                                        И не пропускайте свежие посты в наших социальных сетях!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                answerShow ? (
                    <AnswerBox text={answerText} />
                ):""
            }
        </>
    )
}