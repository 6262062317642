import { useState } from "react"

export const FAQItem = (props: any) => {
    const [open, setOpen] = useState(false)
    return(
        <div className={`faq-item ${open ? "active":""}`}>
            <div className="faq-title" onClick={()=>{setOpen(!open)}}>
                {props.item.title}
            </div>
            <div className="faq-desc" dangerouslySetInnerHTML={{ __html: props.item.content}}></div>
        </div>
    )
}