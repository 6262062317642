import { ChangeEvent, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { GlobalContext } from "../context/GlobalContext"
import { AnswerBox } from "../component/AnswerBox"

export const RegistrationPage = () => {
    const auth = useContext(GlobalContext)
    if(auth.user.token != ""){ window.location.replace("/account/") }

    const [query, setQuery] = useState(false)
    const [answerShow, setAnswerShow] = useState(false)
    const [answerText, setAnswerText] = useState("")
    const [form, setForm] = useState({ 
        name: "",
        email: "",
        password: "",
        cPassword: "",
    })
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(!query){
            auth.user.Registration(form.name, form.email, form.password, form.cPassword).then(function(response){
                setForm({   
                    name: response.error == true ? form.name : "",
                    email: response.error == true ? form.email : "",
                    password: "",
                    cPassword: ""
                })
                setAnswerShow(true)
                setAnswerText(response.msg)
            }).catch(function(error){
                alert("error")
            })
        }
    }

    useEffect(()=>{
        if(answerShow == true && answerText != ""){
            setTimeout(()=>{
                setAnswerShow(false)
            }, 5000);
        }
    },[answerShow])
    
    return(
        <>
            <section className="login">
                <div className="content">
                    <div className="login-wrapper">
                        <form onSubmit={handleSubmit} className="form-page">
                            <h1 className="title">Регистация</h1>
                            <p>Зарегистрированным пользователям доступен личный кабинет с историей заказов</p>
                            <label>
                                Имя
                                <input type="text" required name="name" value={form.name} onChange={changeHandler} />
                            </label>
                            <label>
                                Email
                                <input type="email" required name="email" value={form.email} onChange={changeHandler} />
                            </label>
                            <label>
                                Пароль
                                <input type="password" required name="password" value={form.password} onChange={changeHandler} />
                            </label>
                            <label>
                                Повторите пароль
                                <input type="password" required name="cPassword" value={form.cPassword} onChange={changeHandler} />
                            </label>
                            <div className="btn-link">
                                <button>Регистрация</button>
                                <Link to="/login/">Войти</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            {
                answerShow ? (
                    <AnswerBox text={answerText} />
                ):""
            }
        </>
    )
}