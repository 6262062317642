export const Pagination = (props: any) => {
    return(
        <>
            <div className="pagination-wrapper">
                <div className="pagination">
                    {
                        props.page > 1 ? (
                            <span 
                                onClick={()=>{
                                    props.LoadProducts(props.page-1)
                                }}
                                className="pagination-arrow"
                            >
                                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M4 12L8 8M4 12L8 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        ):""
                    }
                    {
                        props.page-2 >= 1 ? (
                            <span
                                onClick={()=>{
                                    props.LoadProducts(props.page-2)
                                }}
                            >
                                {props.page-2}
                            </span>
                        ):""
                    } 
                    {
                        props.page-1 >= 1 ? (
                            <span
                                onClick={()=>{
                                    props.LoadProducts(props.page-1)
                                }}
                            >
                                {props.page-1}
                            </span>
                        ):""
                    } 
                    <span className="target">
                        {props.page}
                    </span> 
                    {
                        props.page+1 <= props.pageCount ? (
                            <span
                                onClick={()=>{
                                    props.LoadProducts(props.page+1)
                                }}
                            >
                                {props.page+1}
                            </span>
                        ):""
                    } 
                    {
                        props.page+2 <= props.pageCount ? (
                            <span
                                onClick={()=>{
                                    props.LoadProducts(props.page+2)
                                }}
                            >
                                {props.page+2}
                            </span>
                        ):""
                    } 
                    {
                        props.page < props.pageCount ? (

                            <span
                                onClick={()=>{
                                    props.LoadProducts(props.page+1)
                                }}
                                className="pagination-arrow"
                            >
                                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12H20M20 12L16 8M20 12L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        ):""
                    } 
                </div>
            </div>
        </>
    )
}