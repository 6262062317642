export const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <button 
            id="prev" 
            type="button" 
            className="prev btn btn-juliet"
            onClick={onClick}
        ></button>
    );
}