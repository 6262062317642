import { Link, useParams } from "react-router-dom"
import { SampleNextArrow } from "../component/Arrows/SampleNextArrow";
import { SamplePrevArrow } from "../component/Arrows/SamplePrevArrow";
import Slider from "react-slick";
import targetimage1 from "../image/target-image1.png"
import shoes_img from "../image/shoes_img.png"
import shoes_img2 from "../image/shoes_img2.png" 
import { useContext, useEffect, useState } from "react";
import { ProductSlider } from "../component/ProductSlider";
import { Loyalty } from "../component/Loyalty";
import { FooterForm } from "../component/FooterForm";
import { host, Product } from "../classes";
import { Loader } from "../component/Loader";
import { GlobalContext } from "../context/GlobalContext";
import { AnswerBox } from "../component/AnswerBox";

export const ProductPage = () => {
    const auth = useContext(GlobalContext)
    const setting = {
        slidesToShow: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const [answerShow, setAnswerShow] = useState(false)
    const [answerText, setAnswerText] = useState("")

    useEffect(()=>{
        if(answerShow == true && answerText != ""){
            setTimeout(()=>{
                setAnswerShow(false)
            }, 5000);
        }
    },[answerShow])


    const { slug } = useParams()
    const [load, setLoad] = useState(true) 
    const [product, setProduct] = useState(new Product())
    const [targetTab, setTargetTap] = useState(0)

    const [targetSize, setTargetSize] = useState(0)

    const [inFavourites, setInFavourites] = useState(auth.user.favourites.includes(product.pk))

    const UpdateFavourites = () => {
        auth.user.SetFavourites(product.pk)
        setInFavourites(!inFavourites)
    }
    
    useEffect(()=>{
        if(slug){
            product.slug = slug
            product.LoadData().then(function(response){
                setLoad(false)
            }).catch(function(error){
                setLoad(false)
                alert("error")
            })
        }
    },[])

    if(load){
        return(
            <Loader />
        )
    }
    
    return(
        <>
            <section className="target-product">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <Link to="/catalog/">Каталог</Link> / <span>Продукт</span>
                    </div>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="target-product__gallery">
                                <Slider {...setting}>
                                    {
                                        product.gallery ? (
                                            product.gallery.map((item: any, index: number) => {
                                                return(
                                                    <div key={index}>
                                                        <div 
                                                            className="target-product__gallery-image"
                                                            style={{
                                                                backgroundImage: `url('${host}${item.image}')`
                                                            }}
                                                        ></div>
                                                    </div>
                                                )
                                            })
                                        ):""
                                    }
                                </Slider>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            {
                                product.sticker ? (
                                    <div className="target-type">
                                        {product.sticker}
                                    </div>
                                ):""
                            }
                            <h1>
                                {product.title}
                            </h1>
                            <p className="target-product__brand">
                                <Link to={`/catalog/?brand=${product.brand.pk}`}>
                                    {product.brand.title}
                                </Link>
                            </p>
                            <div className="target-product__price product-item__price">
                                {
                                    product.minPrice == product.minSalePrice ? (
                                        `От ${product.minPrice} ₽`
                                    ):(
                                        <>
                                            <span className="old">От {product.minPrice} ₽</span>
                                            <span className="new"> {product.minSalePrice} ₽</span>
                                        </>
                                    )
                                }
                            </div>
                            {
                                targetSize == 0 ? (
                                    <div className="target-product__size">Размер не выбран</div>
                                ):""
                            }
                            <div className="target-product__btns">
                                <button
                                    onClick={()=>{
                                        if(targetSize != 0){
                                            auth.user.cart.AddToCart(auth.user.token, targetSize, product.pk).then(function(response){
                                                setAnswerText("Товар успешно добавлен в корзину")
                                                setAnswerShow(true)
                                            }).catch(function(error){
                                                alert("error")
                                            })
                                        }
                                    }}
                                >
                                    Добавить в корзину
                                </button>
                                <div 
                                    className={`target-product__favorite ${inFavourites ? "active":""}`}
                                    onClick={()=>{
                                        UpdateFavourites() 
                                    }}
                                >
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2008_2144)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0002 8.125H13.5418C13.5418 5.93017 15.9187 3.79167 18.3518 3.79167C21.6278 3.79167 23.2918 6.40467 23.2918 9.49217C23.2918 17.2185 13.0002 22.2083 13.0002 22.2083C13.0002 22.2083 2.7085 17.2185 2.7085 9.49217C2.7085 6.40467 4.3725 3.79167 7.6485 3.79167C10.0817 3.79167 12.4585 5.93017 12.4585 8.125H13.0002Z" fill="white" stroke="black" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2008_2144">
                                                <rect width="26" height="26" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                            <div className="target-product__table">
                                Таблица размеров*
                            </div>
                            <p className="target-product__table-star">*Бирка на ваших кроссовках поможет выбрать правильный размер</p>
                            <div className="target-product__size-wrapper">
                                <div className="row">
                                    {
                                        product.remains.map((item, index) => {
                                            return(
                                                <div 
                                                    className="col-4 col-md-3"
                                                    onClick={()=>{
                                                        if(item.available)
                                                            setTargetSize(item.pk)
                                                    }}
                                                >
                                                    <div className={`target-product__size-item ${targetSize == item.pk ? "active":""} ${!item.available ? "grey":""}`}>
                                                        <b>{item.title}</b><br/>
                                                        {
                                                            item.available ? (
                                                                item.salePrice != item.price ? (
                                                                    <>
                                                                        <span className="target-product__new">{item.salePrice} ₽</span><br/>
                                                                        <span className="target-product__old">{item.price} ₽</span>
                                                                    </>
                                                                ):(
                                                                    item.salePrice
                                                                )
                                                            ):"Нет в наличии"
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: 30}}>
                        <div className="col-lg-7">
                            <div className="target-product__desc">
                                <div className="target-product__tabs">
                                    <div onClick={()=>{setTargetTap(0)}} className={`${targetTab == 0 ? "active":""}`}>О бренде</div>
                                    <div onClick={()=>{setTargetTap(1)}} className={`${targetTab == 1 ? "active":""}`}>Как определить размер ?</div>
                                    <div onClick={()=>{setTargetTap(2)}} className={`${targetTab == 2 ? "active":""}`}>Доставка и оплата</div>
                                    <div onClick={()=>{setTargetTap(3)}} className={`${targetTab == 3 ? "active":""}`}>Обмен и возврат</div>
                                </div>
                                {
                                    targetTab == 0 ? (
                                        <div className="target-product__tabs-content">
                                            <p>{product.brand.miniDesc}</p>
                                        </div>
                                    ) : targetTab == 1 ? (
                                        <div className="target-product__tabs-content">
                                            <p>2_Мы осуществляем адресную доставку по всей России. Стоимость и сроки доставки рассчитываются индивидуально на этапе оформления заказа. Для Москвы и Московской области доступна примерка, оплата при получении и самовывоз из наших магазинов:</p>
                                            <p>1) Москва, Кутузовский проспект 48, Галереи "Времена Года", 3 этаж.</p>
                                            <p>2)Московская область, деревня Воронки, 1 к. 4. ТЦ Архангельское Аутлет, бутик Sortage.</p>
                                            <p>Подробнее об условиях доставки можно узнать на этой странице</p>
                                        </div>
                                    ) : targetTab == 0 ? (
                                        <div className="target-product__tabs-content">
                                            <p>Мы осуществляем адресную доставку по всей России. Стоимость и сроки доставки рассчитываются индивидуально на этапе оформления заказа. Для Москвы и Московской области доступна примерка, оплата при получении и самовывоз из наших магазинов:</p>
                                            <p>1) Москва, Кутузовский проспект 48, Галереи "Времена Года", 3 этаж.</p>
                                            <p>2)Московская область, деревня Воронки, 1 к. 4. ТЦ Архангельское Аутлет, бутик Sortage.</p>
                                            <p>Подробнее об условиях доставки можно узнать на этой странице</p>
                                        </div>
                                    ) : (
                                        <div className="target-product__tabs-content">
                                            <p>Мы осуществляем адресную доставку по всей России. Стоимость и сроки доставки рассчитываются индивидуально на этапе оформления заказа. Для Москвы и Московской области доступна примерка, оплата при получении и самовывоз из наших магазинов:</p>
                                            <p>1) Москва, Кутузовский проспект 48, Галереи "Времена Года", 3 этаж.</p>
                                            <p>2)Московская область, деревня Воронки, 1 к. 4. ТЦ Архангельское Аутлет, бутик Sortage.</p>
                                            <p>Подробнее об условиях доставки можно узнать на этой странице</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="target-product__info">
                                <div>
                                    Для данного товара доступен авиа-экспресс. Среднее время доставки:<br/><span>8 рабочих дней</span>
                                </div>
                                <div>
                                    Данный товар доступен под заказ. Среднее время доставки:<br/><span>15-20 рабочих дней </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="target-product__links">
                        <Link to={`/catalog/?brand=${product.brand.pk}`}>Другие товары {product.brand.title}</Link>
                        <Link to={`/catalog/${product.category?.slug}/`}>{product.category?.title}</Link>
                    </div>
                </div>
            </section>

            {
                answerShow ? (
                    <AnswerBox text={answerText} />
                ):""
            }
            
            {
                product.top.length > 0 ? (
                    <ProductSlider title={"Вам также может понравиться"} link={`/compilations/desc/`} list={product.top} />
                ):""
            }

            <div style={{height: 50}}></div>
 
            {
                product.discount.length > 0 ? (
                    <ProductSlider title={"Сейчас со скидкой"} link={`/compilations/desc/`} list={product.discount} />
                ):""
            }

            <Loyalty />
            <FooterForm />
        </>
    )
}