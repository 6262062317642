import { Link } from "react-router-dom"
import { FooterForm } from "../component/FooterForm"
import { FAQItem } from "../component/FAQItem"
import { useEffect, useState } from "react"
import { FAQ } from "../classes"
import { Loader } from "../component/Loader"

export const FAQPage = () => {
    const [faq, setFAQ] = useState(new FAQ())
    const [load, setLoad] = useState(true)

    useEffect(()=>{
        setLoad(true)
        faq.LoadData().then(function(){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])

    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <section className="faq">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>FAQ</span>
                    </div>
                    <h1 className="title">FAQ</h1>
                    <div className="faq-wrapper">
                        {
                            faq.list ? (
                                faq.list.map((item, index) => {
                                    return(
                                        <FAQItem item={item} />
                                    )
                                })
                            ):""
                        }
                    </div>
                </div>
            </section>
            
            <FooterForm />
        </>
    )
}