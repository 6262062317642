import { Link } from "react-router-dom"
import { GlobalContext } from "../../context/GlobalContext"
import { useContext, useEffect, useState } from "react"
import { Loader } from "../../component/Loader"
import { Pagination } from "../../component/Pagination"

export const AccountPage = () => {
    const auth = useContext(GlobalContext)
    if(auth.user.token == ""){ window.location.replace("/login/") }

    const [load, setLoad] = useState(true)
    
    const LoadData = (page: number = 1) => {
        setLoad(true)
        auth.user.LoadOrders(page).then(function(response){
            setLoad(false)
            //alert("good")
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{
        LoadData()
    },[])


    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <section className="account-list">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>Аккаунт</span>
                    </div>
                    <h1 className="title">Аккаунт</h1>
                    
                    <div className="row">
                        <div className="col-md-3 col-lg-2">
                            <div className="account-menu">
                                <ul>
                                    <li>
                                        <Link className="active" to={`/account/`}>Заказы</Link>
                                    </li>
                                    <li>
                                        <Link to={`/account/setting/`}>Настройки</Link>
                                    </li>
                                    <li>
                                        <Link to={`/favorites/`}>Избранное</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-9 col-lg-10">
                            <div className="row">
                                {
                                    auth.user.orders.length ? (
                                        auth.user.orders.map((item, index) => {
                                            return(
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="account-list__wrapper">
                                                        <Link to={`/account/${item.pk}/`} className="account-list__item">
                                                            <h3><b>Дата заказа:</b> {item.updateDate}</h3>
                                                            <p>
                                                                <b>Статус заказа: </b>{item.status}<br/>
                                                                <b>Цена заказа: </b>{item.finPrice} руб.<br/>
                                                                <b>Кол-во товаров: </b>{item.finCount}
                                                            </p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ):""
                                } 
                            </div>

                            {
                                auth.user.pageCount > 1 ? (
                                    <Pagination
                                        page={auth.user.page}
                                        pageCount={auth.user.pageCount}
                                        LoadProducts={LoadData}
                                    />
                                ):""
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}