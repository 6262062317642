import { useEffect, useState } from "react"
import { FooterForm } from "../../component/FooterForm"
import { Loyalty } from "../../component/Loyalty"
import { ProductSlider } from "../../component/ProductSlider"
import shoes_img from "../../image/shoes_img.png"
import shoes_img2 from "../../image/shoes_img2.png" 
import { Link, useParams } from "react-router-dom"
import brandbg from "../../image/brand-bg.png"
import { BrandItem } from "../../classes"
import { Loader } from "../../component/Loader"
export const BrandItemPage = () => {
    const {slug} = useParams()
    const [item, setItem] = useState(new BrandItem())
    const [load, setLoad] = useState(true)

    useEffect(()=>{
        if(slug){
            item.slug = slug
            item.LoadData().then(function(response){
                setLoad(false)
            }).catch(function(error){
                alert("error")
            })
        }
    },[])
    
    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <section className="brand-content">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <Link to="/brands/">Бренды</Link> / <span>{item.title}</span>
                    </div>
                    <h1 className="title">{item.title}</h1>
                    <div className="brand-banner" style={{backgroundImage: `url('http://api.teststeps.ru${item.banner}')`}}></div>
                    <div className="brand-content__wrapper" dangerouslySetInnerHTML={{ __html: item.content} } />

                    <ProductSlider title="Популярные товары" link="/" list={item.products} />
                </div>
            </section>


            <Loyalty />
            <FooterForm />
        </>
    )
}