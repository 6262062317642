import { useState } from "react"

export const CatalogCategory = (props: any) => {
    const [showAll, setShowAll] = useState(false)
    
    const PrintNode = (node: any) => {
        return(
            <li value={node.slug}>
                <span 
                    onClick={()=>{
                        let flag = false
                        const array = []
                        props.selected.forEach((item2: any) => {
                            if(item2 == node.pk)
                                flag = true 
                            else 
                                array.push(item2)
                        })

                        if(!flag) 
                            array.push(node.pk)
                        props.setSelected(array)
                        console.log(array)
                    }}
                    className={`${props.selected.indexOf(node.pk) >= 0 ? "active":""}`}
                >
                    {node.title}
                </span>
                {
                    node.children ? (
                        node.children.length ? (
                            <ul>
                                {
                                    node.children.map((item: any, index: number) => {
                                        return(
                                            PrintNode(item)
                                        )
                                    })
                                }
                            </ul>
                        ):""
                    ):""
                }
            </li>
        )
    }
    
    return(
        <>
            <div className={`catalog-filter__cat ${showAll ? "active":""}`}>
                <h3 onClick={()=>{setShowAll(!showAll)}}>{props.title}</h3>
                {
                    showAll ? (
                        <ul>
                            {
                                props.list.length ? (
                                    props.list.map((item: any, index: number) => {
                                        return(
                                            PrintNode(item)
                                        )
                                    })   
                                ):""
                            }
                        </ul>
                    ):""
                }
            </div>
        </>
    )
}